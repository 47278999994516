<template>
  <section class="gap-16">
    <span
      class="banner_image md:w-1/2"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    />
    <div class="w-full md:w-1/2">
      <h3>The best web scanner to<br><span>resolve</span> technical and SEO problems.</h3>
      <b-button
        class="button-banner mt-4"
        @click="$router.resolve(({ path: `/seocloud`}))"
      >
        Start monitoring your site
      </b-button>
    </div>

  </section>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import bannerImage from '@/assets/images/banners/free-account-banner@2x-v2.png'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      image: bannerImage,
    }
  },
}
</script>

<style scoped>
section{
    background: transparent linear-gradient(106deg, #181E38 0%, #7838FF 100%) 0% 0% no-repeat padding-box;
    height: 565px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    display: flex;
    align-items: center;
    width: 100%;
}
h3{
  width: 90%;
}
h3,h3 span{
  font: normal normal bold 48px/48px "Hurme Geometric Sans 3" !important;
  margin: 0;
  color: #FFFFFF;
}
h3 span{
  color: #CA34FF;
}
.button-banner{
  font: normal normal bold 16px/34px "Hurme Geometric Sans 3" !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #CA34FF 0% 0% no-repeat padding-box!important;
  box-shadow: 0px 6px 9px #CA34FF26!important;
  border: none;
  border-radius: 28px;
  display: block
}
.banner_image{
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
}
@media (max-width: 767px) {
  span{
    display: none;
  }
  .button-banner{
    margin: auto;
  }
  h3{
    margin: auto;
    text-align: center;
    margin-bottom: 4rem;
  }
}
</style>