<template>
  <b-sidebar
    id="screenshot-preview-sidebar"
    name="screenshot-preview-sidebar"
    :visible="isPreviewsSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:isPreviewsSidebarActive', val)"
  >
    <div class="sidebar-header d-flex justify-content-between content-sidebar-header px-2 py-2">
      <PreviewsTitle
        class="w-11/12"
        :url="images.url"
        :comment="images.comment"
        :date="images.date"
        :type-device="typeDevice"
      />
      <span class="flex justify-end w-1/12">
        <feather-icon
        id="exitIcon"
        size="21"
        icon="XIcon"
        class="cursor-pointer"
        @click="closeSidebar"
      />
      <feather-icon
        id="backIcon"
        size="21"
        icon="ArrowLeftIcon"
        class="cursor-pointer"
        @click="closeSidebar"
      />
      </span>
    </div>
    <div class="image-in-sidebar">
      <img
        :src="images[typeDevice]"
        alt="Screenshot"
      >
    </div>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import PreviewsTitle from './PreviewsTitle.vue'

export default {
  name: 'SidebarPreviewsScreenshots',
  components: {
    BSidebar, PreviewsTitle,
  },
  props: {
    isPreviewsSidebarActive: {
      type: Boolean,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    typeDevice: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalIsPreviewsSidebarActive: this.isPreviewsSidebarActive,
    }
  },
  watch: {
    isPreviewsSidebarActive(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
  },
  methods: {
    handleSidebarVisibilityChange(val) {
      this.internalIsPreviewsSidebarActive = val
      this.$emit('update:isPreviewsSidebarActive', val)
    },
    closeSidebar() {
      this.handleSidebarVisibilityChange(false)
    },
  },
}
</script>

<style>
#screenshot-preview-sidebar{
  width: 80vw;
  background: white;
}
#screenshot-preview-sidebar .image-in-sidebar img{
  margin:auto;

  box-shadow:
  0px 0px 2.2px rgba(0, 0, 0, 0.036),
  0px 0px 5.3px rgba(0, 0, 0, 0.054),
  0px 0px 10px rgba(0, 0, 0, 0.069),
  0px 0px 17.9px rgba(0, 0, 0, 0.085),
  0px 0px 33.4px rgba(0, 0, 0, 0.106),
  0px 0px 80px rgba(0, 0, 0, 0.15)
;
}
.image-in-sidebar{
  padding: 0 25px;
  margin-bottom: 25px;
}
.image-in-sidebar img {
  margin: auto;
  max-width: 100% !important;
  height: auto !important;
  display: block !important;
}
#screenshot-preview-sidebar h3{
  text-transform: capitalize;
  color: #5e5873;
  font: normal normal bold 23px/30px "Hurme Geometric Sans 3" !important;
}
#screenshot-preview-sidebar .sidebar-header{
  background: rgb(240, 241, 247);
  margin-bottom: 20px;
  padding: 0 25px;
}
#screenshot-preview-sidebar .sidebar-header p{
  margin-bottom: 0;
}
#screenshot-preview-sidebar .sidebar-header p:last-child{
  margin-bottom: 0.5rem;
}
#backIcon{
  display: none;
}
@media (max-width:840px){
  #screenshot-preview-sidebar{
    width: 100%;
  }
  #exitIcon{
    display: none;
  }
  #backIcon{
    display: block;
  }
}
</style>