<template>
  <section :style="{ backgroundImage: 'url(' + image + '),linear-gradient(115deg, #181e38 47%, #7838ff 180%)' }">
    <div
      class="container"
    >
      <div class="flex justify-content-between pt-1">
        <a
          href="http://seocloud.ai/"
          target="_blank"
        ><img
          class="object-contain"
          alt="logo"
          :src="logo"
          width="190px"
          height="37px"
        ></a>
        <div>
          <b-button
            variant="default"
            class="bg-transparent mr-2 text-white"
            @click="$router.resolve(({ path: `/login`}))"
          >
            Login
          </b-button>
          <b-button
            variant="none"
            class="register-btn text-white"
            @click="$router.resolve(({ path: `/sign-up`}))"
          >
            Sign up for free
          </b-button>
        </div>
      </div>
      <div
        v-if="pageTitle"
        class="text-center text-white py-14"
      >
        <h1 class="text-white">
          {{ pageTitle }}
        </h1>
        <a
          v-if="url"
          class="link-site flex align-items-center justify-content-center"
          :href="'//'+url"
          target="_blank"
        ><feather-icon
          size="20"
          icon="ExternalLinkIcon"
          class="cursor-pointer"
        />{{ urlText ? urlText : url }}</a>
      </div>
    </div>
  </section>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import bannerImage from '@/assets/images/banners/stripes-hero2.svg'
import logoImage from '@/assets/images/logo/logo-seocloud.png'

export default {
  components: {
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pageTitle: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: true,
    },
    urlText: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      image: bannerImage,
      logo: logoImage,
    }
  },
}
</script>

<style>
.register-btn{
  top: 59px;
  left: 1450px;
  width: 174px;
  height: 46px;
  /* UI Properties */
  background: #CA34FF 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
}
.link-site{
  color: white;
  font-size: 16px;
}
.link-site svg{
  margin-right: 6px;
}
</style>