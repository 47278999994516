<template>
  <div>
    <b-sidebar
      id="sidebar-register-sidebar"
      ref="sidebar_register_sidebar"
      :visible="showOpenRegisterSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-register-sidebar', val)"
    >
      <template #default="{ }">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-light"
          style="color: #c1c1c1; display: block; text-align: left; width: 40px;"
          class="btn-icon pl-0 mb-0 pb-25 ml-2 mt-1"
          @click.prevent="submitEditar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
        </b-button>
        <div
          class="b-sidebar-body"
          style="padding: 80px 40px 40px 40px;"
        >
          <b-tabs class="tabsViewErrors tabsRegister" align="center">
            <b-tab
              title="Login"
              :active="tabActive === 'login' ? true : false"
              @click.prevent="tabActive = 'login'"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-light"
                style="color: #c1c1c1; display: block; text-align: left; width: 40px;"
                class="btn-icon pl-0 mb-0 pb-25"
                @click.prevent="resetPass = false"
                v-if="resetPass"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="25"
                />
              </b-button>

              <h3
                class="mb-0 pb-0"
                style="font-weight: 400; color: #181E38 !important; margin-top: 40px !important;"
              >
                {{ resetPass ? 'Reset your password' : 'Login to your SEOcloud account' }}
              </h3>

              <validation-observer ref="loginForm" v-if="!resetPass">
                <b-form
                  class="auth-login-form mt-2"
                >
                  <b-form-group
                    label-for="login-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      vid="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        name="login-email"
                        style="height: 52px;"
                        trim
                        placeholder="Enter email"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="mb-50">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Enter password"
                          style="height: 52px;"
                        />
                        <b-input-group-append
                          is-text
                          class="cursor-pointer"
                          @click="togglePasswordVisibilityLogin"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIconLogin"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-link
                    @click.prevent="resetPass = true"
                    class=""
                  >
                    <small>Forgot password?</small>
                  </b-link>

                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    class="mt-2 font-weight-bold"
                    style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
                    @click.prevent="validationFormLogin"
                    :disabled="proccessInfo"
                  >
                    Log in
                    <b-spinner small class="mb-25" v-if="proccessInfo" />
                  </b-button>
                </b-form>
              </validation-observer>

              <div class="auth-footer-btn d-flex justify-content-center mt-1" v-if="!resetPass">
                <b-button
                  variant="google"
                  class=""
                  style="font-weight: 400; background: #f0f1f1; color: #676b85 !important; font-weight: bold; padding: 15px 30px !important; border-radius: 39px; height: 52px; font-size: 20px;"
                  block
                  @click="googleLogin"
                >
                  <b-img
                    :src="require('@/assets/images/icons/google.png')"
                    style="height: 24px; margin-right: 15px; position: relative; top: -4px;"
                    width="23px"
                    height="24px"
                    alt="img google"
                  />
                  <span>Log in with Google</span>
                </b-button>
              </div>

              <validation-observer ref="simpleRules" v-else>
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="validationFormResetPassword"
                >
                  <b-form-group
                    label-for="forgot-password-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="forgot-password-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="forgot-password-email"
                        placeholder="Enter email"
                        style="height: 52px;"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="disabled"
                    class="mt-2 font-weight-bold"
                    style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
                  >
                    Send password reset email
                  </b-button>
                </b-form>
              </validation-observer>

              <b-card-text class="text-center mt-2 mb-2 text-card-register">
                <span style="color: #676b85;">Need a SEOcloud.io account?</span>
                <b-link @click.prevent="tabActive = 'register', resetPass = false">
                  <span>&nbsp;Sign up for free</span>
                </b-link>
              </b-card-text>
            </b-tab>
            <b-tab
              title="Sign up"
              :active="tabActive === 'register' ? true : false"
              @click.prevent="tabActive = 'register', resetPass = false"
            >
              <h3
                class="mb-0 pb-0"
                style="font-weight: 400; color: #181E38 !important; margin-top: 40px !important;"
              >
                Create your free account
              </h3>

              <validation-observer ref="registerForm">
                <b-form
                  class="auth-register-form mt-2"
                >
                  <b-form-group
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="userEmail"
                        name="register-email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter email"
                        style="height: 52px;"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label-for="register-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="password"
                          class="form-control-merge"
                          :type="password1FieldType"
                          :state="errors.length > 0 ? false:null"
                          name="register-password"
                          placeholder="Enter password"
                          style="height: 52px;"
                        />
                        <b-input-group-append
                          is-text
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        >
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label-for="reset-password-confirm"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|password|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-confirm"
                          v-model="cPassword"
                          :type="password2FieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false:null"
                          name="reset-password-confirm"
                          placeholder="Confirm your password"
                          style="height: 52px;"
                        />
                        <b-input-group-append
                          is-text
                          class="cursor-pointer"
                          @click="togglePassword2Visibility"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <p class="text-right mt-2 ">
                    <span style="">By signing you are agreeing to Seocloud </span>
                    <b-link :to="{name:'auth-login'}">
                      <span style="text-decoration: underline;">Terms & Conditions</span>
                    </b-link>
                  </p>

                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    class="mt-2 font-weight-bold"
                    style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
                    @click.prevent="validationForm"
                    :disabled="proccessInfo"
                  >
                    Sign up
                    <b-spinner small class="mb-25" v-if="proccessInfo" />
                  </b-button>
                </b-form>
              </validation-observer>

              <div class="auth-footer-btn d-flex justify-content-center mt-1">
                <b-button
                  variant="google"
                  class=""
                  style="font-weight: 400; background: #f0f1f1; color: #676b85 !important; font-weight: bold; padding: 15px 30px !important; border-radius: 39px; height: 52px; font-size: 20px;"
                  block
                  @click="google"
                >
                  <b-img
                    :src="require('@/assets/images/icons/google.png')"
                    style="height: 24px; margin-right: 10px; position: relative; top: -4px;"
                    width="23px"
                    height="24px"
                    alt="img google"
                  />
                  Sign up
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BTabs, BTab,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
  BLink, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BImgLazy, BModal, VBModal, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, email, password, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapMutations, mapActions, mapState } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { db, auth, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BImgLazy,
    BModal,
    BSpinner,
    BTabs,
    BTab,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  model: {
    prop: 'showOpenRegisterSidebar',
    event: 'update:show-open-register-sidebar',
  },
  props: ['showOpenRegisterSidebar','gologin'],
  data() {
    return {
        status: '',
        username: '',
        userEmail: '',
        password: '',
        cPassword: '',
        sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
        ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
        proccessInfo: false,
        tabActive: 'login',
        // validation
        email,
        required,
        password,
        confirmed,
        disabled: true,
        resetPass: false,
        // Toggle Password
        passwordFieldType: 'password',
        password1FieldType: 'password',
        password2FieldType: 'password',
    }
  },
  computed: {
    ...mapState(['usuario']),
    passwordToggleIconLogin() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if(store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    userEmail(val) {
      this.disabled = false
    },
    gologin(val) {
      if(val) {
        this.tabActive = 'login'
      } else {
        this.tabActive = 'register'
      }
    },
    showOpenRegisterSidebar(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        localStorage.removeItem('configCrawl')
        localStorage.removeItem('configRun')
        localStorage.removeItem('loginRegisterProcess')
        localStorage.removeItem('configCrawlViewSite')
        document.body.style.overflow = 'auto'
      }
    },
  },
  methods: {
    ...mapMutations(['nuevoUsuario']),
    ...mapActions(['crearUsuario', 'setUsuario', 'cerrarSesionShared']),
    togglePasswordVisibilityLogin() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePasswordVisibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    mailWelcome(email) {
    },
    submitEditar(payload) {
      this.$refs.sidebar_register_sidebar.hide()
    },
    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider()
      this.ingresarLogin(provider)
    },
    async ingresarLogin(provider) {
      // ingreso con cuenta de google
      firebase.auth().languageCode = 'en'
      this.cerrarSesionShared()

      try {
        // Ingreso Usuario
        const result = await firebase.auth().signInWithPopup(provider)
        const { user } = result

        const doc = await db.collection('Users').doc(user.uid).get()

        // Construir usuario
        const usuario = {
          nombre: doc.exists ? doc.data().nombre : user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
          role: 'user',
          status: 'active',
          typeRegister: 'Regular register page',
          totalSites: 10,
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        // el usuario ya existe
        if(!doc.exists) {
          // Guardar el usuario
          await db.collection('Users').doc(usuario.uid).set(
            usuario,
          )
          setTimeout(() => {
            db.collection('Projects').add({
              name: 'My sites',
              color: '#d1d1d1',
              isMySites: true,
              numberSites: 0,
              numberTeamMembers: 0,
              owner: {
                nombre: usuario.nombre,
                email: usuario.email,
                uid: usuario.uid,
                foto: '',
              },
              teamMembers: []
            })
            .then(docRef => {
              // update the uid of the project
              db.collection('Projects').doc(docRef.id).update({
                id: docRef.id,
              })
              .catch(error => {
                console.error(error)
              })
      
              // update teams owner
              db.collection('Users').doc(usuario.uid).update({
                teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                updated: Date.now(),
                mySitesId: docRef.id
              })
              .catch(error => {
                console.error(error)
              })
      
              db.collection('Users').doc(usuario.uid).collection('projects').doc(docRef.id).set({
                id: docRef.id,
                name: 'My sites',
                color: '#d1d1d1',
                isMySites: true,
                numberSites: 0,
                // sites: site ? site : '',
                numberTeamMembers: 0,
                owner: {
                  nombre: usuario.nombre,
                  email: usuario.email,
                  uid: usuario.uid,
                  foto: '',
                },
                teamMembers: []
              })
              .catch(error => {
                console.error(error)
              })
            })
          }, 500)
        }

        this.nuevoUsuario(usuario)
        var getStorageCrawlConfig = localStorage.getItem('loginRegisterProcess')
        if(getStorageCrawlConfig) {
          localStorage.setItem('configLicense', true)
          localStorage.removeItem('loginRegisterProcess')
        } else {
          localStorage.setItem('configLicense', true)
        }
        // localStorage.setItem('firstLogin', Date.now())
        
        if(this.$route.name === 'shared-site' && this.$route.query.site && user.uid) {
          db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
          .then(siteQueryWebsite => {
            if(siteQueryWebsite.size) {
              siteQueryWebsite.forEach(site => {
                var teamMembers = site.data().teamMembers && site.data().teamMembers.length ? site.data().teamMembers : []
                if(!teamMembers.includes(user.uid)) {
                  localStorage.setItem('addSite', 'new')
                }
              })
            }
          })
        }

        // obtener ruta
        // this.$ability.update(usuario.ability)
        // this.$router.replace(getHomeRouteForLoggedInUser(doc.exists && (doc.data().teams && doc.data().teams.length > 1) ?  usuario.role : 'staff' ))
        setTimeout(() => {
          this.proccessInfo = false
          // location.reload()
        }, 1500)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    async ingresoUsuario(usuario) {
      this.cerrarSesionShared()
      // ingreso con email y contraseña
      try {
        const signIn = await auth.signInWithEmailAndPassword(usuario.email, usuario.password)

        const doc = await db.collection('Users').doc(signIn.user.uid).get()

        // el usuario ya existe
        if(doc.exists) {
          // login condition
          const UsuarioLogueado = {
            email: signIn.user.email,
            uid: signIn.user.uid,
          }

          const userData = doc.data()
          // console.log(userData)
          // Construir usuario
          this.nuevoUsuario(userData)
          var getStorageCrawlConfig = localStorage.getItem('loginRegisterProcess')
          if(getStorageCrawlConfig) {
            localStorage.setItem('configLicense', true)
            localStorage.removeItem('loginRegisterProcess')
          } else {
            localStorage.setItem('configLicense', true)
          }
          // localStorage.setItem('firstLogin', Date.now())

          if(this.$route.name === 'shared-site' && this.$route.query.site && signIn.user.uid) {
            db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
            .then(siteQueryWebsite => {
              if(siteQueryWebsite.size) {
                siteQueryWebsite.forEach(site => {
                  var teamMembers = site.data().teamMembers && site.data().teamMembers.length ? site.data().teamMembers : []
                  if(teamMembers.includes(signIn.user.uid)) {
                    localStorage.setItem('addSiteView', 'new')
                  } else {
                    localStorage.setItem('addSite', 'new')
                  }
                })
              }
            })
          }

          this.$ability.update(userData.ability)

          // this.$router.replace(getHomeRouteForLoggedInUser(doc.exists && (doc.data().teams && doc.data().teams.length > 1) ? userData.role : 'staff'))
          // .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome back ${userData.nombre}`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.proccessInfo = false
              // location.reload()
            }, 1500)
          // })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'User does not exist',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        }
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Incorrect password.',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        } else if (error.code === 'auth/user-not-found') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Invalid email or password',
            },
          })
          setTimeout(() => {
          this.proccessInfo = false
          }, 1500)
        } else {
          // console.log(error,error.code)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Invalid email or password',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        }
      }
    },
    validationFormLogin() {
      this.$refs.loginForm.validate().then(success => {
        if(success) {
          this.proccessInfo = true
          this.ingresoUsuario({ email: this.userEmail, password: this.password })
        }
      })
    },
    google() {
      const provider = new firebase.auth.GoogleAuthProvider()
      this.ingresar(provider)
    },
    async ingresar(provider) {
      // ingreso con cuenta de google
      firebase.auth().languageCode = 'en'
      this.cerrarSesionShared()

      try {
        // Ingreso Usuario
        const result = await firebase.auth().signInWithPopup(provider)
        const { user } = result

        const doc = await db.collection('Users').doc(user.uid).get()

        // Construir usuario
        const usuario = {
          nombre: doc.exists ? doc.data().nombre : user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
          role: 'user',
          status: 'active',
          typeRegister: 'Regular register page',
          totalSites: 10,
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        // el usuario no existe
        if(!doc.exists) {
          // Guardar el usuario
          await db.collection('Users').doc(usuario.uid).set(
            usuario,
          )
          setTimeout(() => {
            db.collection('Projects').add({
              name: 'My sites',
              color: '#d1d1d1',
              isMySites: true,
              numberSites: 0,
              numberTeamMembers: 0,
              owner: {
                nombre: usuario.nombre,
                email: usuario.email,
                uid: usuario.uid,
                foto: '',
              },
              teamMembers: []
            })
            .then(docRef => {
                // db.collection('Sites').doc(this.$route.params.id).get()
                // .then(docSite => {
                    // update the uid of the project
                    db.collection('Projects').doc(docRef.id).update({
                        id: docRef.id,
                        numberSites: firebase.firestore.FieldValue.increment(1)
                    })
                    .catch(error => {
                        console.error(error)
                    })
            
                    // update teams owner
                    db.collection('Users').doc(usuario.uid).update({
                        teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                        updated: Date.now(),
                        mySitesId: docRef.id
                    })
                    .catch(error => {
                        console.error(error)
                    })
            
                    db.collection('Users').doc(usuario.uid).collection('projects').doc(docRef.id).set({
                        id: docRef.id,
                        name: 'My sites',
                        color: '#d1d1d1',
                        isMySites: true,
                        numberSites: 1,
                        numberTeamMembers: 0,
                        owner: {
                            nombre: usuario.nombre,
                            email: usuario.email,
                            uid: usuario.uid,
                            foto: '',
                        },
                        teamMembers: []
                    })
                    .then(() => {
                    })
            })
          }, 500)
          this.mailWelcome(user.email)
        }

        this.nuevoUsuario(usuario)
        var getStorageCrawlConfig = localStorage.getItem('loginRegisterProcess')
        if(getStorageCrawlConfig) {
          localStorage.setItem('configLicense', true)
          localStorage.removeItem('loginRegisterProcess')
        } else {
          localStorage.setItem('configLicense', true)
        }
        // localStorage.setItem('firstLogin', Date.now())
        
        localStorage.setItem('addSite', 'new')

        // obtener ruta
        // this.$ability.update(usuario.ability)
        // this.$router.replace(getHomeRouteForLoggedInUser('staff'))
        setTimeout(() => {
          this.proccessInfo = false
          // location.reload()
        }, 1700)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    async registerWithEmail() {
      this.cerrarSesionShared()
      try {
        const result = await this.crearUsuario({
          email: this.userEmail, password: this.password, username: this.username, typeRegister: 'Regular register page', totalSites: 10, idsite: true
        })
        // localStorage.setItem('firstLogin', Date.now())
        
        localStorage.setItem('addSite', 'new')

        var getStorageCrawlConfig = localStorage.getItem('loginRegisterProcess')
        if(getStorageCrawlConfig) {
          localStorage.setItem('configLicense', true)
          localStorage.removeItem('loginRegisterProcess')
        } else {
          localStorage.setItem('configLicense', true)
        }
        
        setTimeout(() => {
          this.proccessInfo = false
          // location.reload()
        }, 1500)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if(success) {
          this.proccessInfo = true
          this.registerWithEmail()
        }
      })
    },
    validationFormResetPassword() {
      this.disabled = true
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          auth.sendPasswordResetEmail(this.userEmail)
            .then(doc => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset link sent, please check your email.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
            // console.log(error.code)
              if (error.code === 'auth/wrong-password') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'The password is not correct',
                  },
                })
              } else if (error.code === 'auth/user-not-found') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'Invalid email or password',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'Invalid email or password',
                  },
                })
              }
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: error.message,
            //     icon: 'EditIcon',
            //     variant: 'danger',
            //   },
            // })
            })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  created() {
    this.proccessInfo = false
  }
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-register-sidebar {
    width: 90%;
    max-width: 593px !important;
}
</style>
