function generateDateString(date, isTime) {
    const dateToParse = !isTime ? date.replaceAll('-', '/') : date
    const datePost = isTime ? new Date((dateToParse)) : new Date(dateToParse)
    const year = datePost.getFullYear()
    const month = datePost.toLocaleString('en-US', { month: 'long' })
    return `${datePost.toLocaleDateString('en-US', { weekday: 'long' })} ${datePost.toLocaleString('en-US', { day: 'numeric' })}th ${month}, ${year}`
}
  
function removeProtocolFromURL(url) {
    // Check if the input is a valid URL
    try {
      new URL(url)
    } catch (error) {
      // If not a valid URL, return the input string unchanged
      return url
    }
  
    // Check if the URL contains a protocol
    if (url.startsWith('http://')) {
      return url.slice(7) // Remove "http://"
    } if (url.startsWith('https://')) {
      return url.slice(8) // Remove "https://"
    }
    return url // No protocol found, return the original URL
}
  
export default {
    generateDateString, removeProtocolFromURL,
}