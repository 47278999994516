/* eslint-disable vue/prop-name-casing */
<template>
  <div>
    <div class="headerContainer mb-2">
      <h3>
        {{ month }} {{ year }}
      </h3>
    </div>
    <b-calendar
      v-if="datesWithData"
      v-model="value"
      :date-info-fn="dateClass"
      locale="en"
      block
      hide-header
      @context="onContext"
    >
      <template v-slot:nav-prev-month>
        <span
          class="arrow-left"
        ><feather-icon
          icon="ChevronLeftIcon"
          class="calendarIcon"
        /></span>
      </template>
      <template v-slot:nav-next-month>
        <span
          class="arrow-right"
        ><feather-icon
          icon="ChevronRightIcon"
          class="calendarIcon"
        /></span>
      </template>
    </b-calendar>
  </div>
</template>

<script>
import { BCalendar } from 'bootstrap-vue'

export default {
  components: {
    BCalendar,
  },
  props: {
    datesWithData: {
      type: Array,
      default: () => [],
    },
    moreRecent: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    value: '',
    context: null,
    month: null,
    year: null,
  }),
  watch: {
    datesWithData() {
      if (this.datesWithData) {
        this.value = !this.value ? this.datesWithData.slice(-1).shift() : this.value
      }
    },
    moreRecent() {
      if (this.moreRecent.length) {
        this.value = this.moreRecent
        this.$emit('update:moreRecent', '')
      }
    },
  },
  methods: {
    dateClass(ymd, date) {
      const day = (`0${date.getDate()}`).slice(-2)
      const month = (`0${date.getMonth() + 1}`).slice(-2)
      const dateInCalendar = `${date.getFullYear()}-${month}-${day}`
      return this.datesWithData.includes(dateInCalendar) ? 'date-with-data' : 'date-whitout-data'
    },
    onContext(ctx) {
      this.context = ctx
      this.year = new Date(this.context.activeDate).getFullYear()
      this.month = new Date(this.context.activeDate).toLocaleString('en-US', { month: 'short' })
      this.$emit('currentDate', ctx.selectedYMD)
    },
  },
}
</script>