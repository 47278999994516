<template>
  <div>
    <b-sidebar
      id="sidebar-right-archive-site"
      ref="sidebar_cancel_crawl"
      :visible="showOpenCancelCrawl"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-cancel-crawl', val)"
    >
      <template #default="{ hide }">

        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body"
          style="padding: 40px;"
        >
          <h3
            class="mb-0 pb-0"
            style="font-weight: 700; color: #181E38 !important;"
          >
            Do you want to cancel the running crawl?
          </h3>
          <!-- <claim-ownership style="padding: 40px;" class="mt-0 pt-0 pb-0" :name="idsite.name" :url="idsite.domain" :keySite="idsite.key" /> -->
          <b-button
            style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-3 mr-1"
            variant="primary"
            @click="cancelFunction"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #FFFFFF;"
            >Confirm</span>
          </b-button>
          <b-button
            style="background: #F0F1F7 !important; border: 1px solid #CFD3E5 !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-3"
            variant="primary"
            @click="hide"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #A7AAB9;"
            >No</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenCancelCrawl',
    event: 'update:show-open-cancel-crawl',
  },
  props: ['idsite', 'showOpenCancelCrawl','crawlID','crawlRunning'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      crawls: '',
    }
  },
  watch: {
    showOpenCancelCrawl(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    idsite(val) {
      // console.log(val)
    //   if(val) {
    //     this.userData = {}
    //     const ref = db.collection('Users').doc(val)
    //     ref.onSnapshot(doc => {
    //       this.usuarios = {}
    //       this.usuarios = {
    //           id: doc.data().uid,
    //           fullName: doc.data().nombre,
    //           firstName: doc.data().firstName ? doc.data().firstName : '',
    //           lastName: doc.data().lastName ? doc.data().lastName : '',
    //           email: doc.data().email,
    //           role: doc.data().role,
    //           avatar: doc.data().foto,
    //           contact: doc.data().contact ? doc.data().contact : '',
    //           permissions: doc.data().permissions ?  doc.data().permissions : [],
    //       }
    //       this.emailOld = doc.data().email
    //       this.userData = this.usuarios
    //     })
    //   }
    },
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_cancel_crawl.hide()
    },
    cancelFunction() {
      db.collection('Crawls').doc(this.crawlID).get()
      .then(eleCrawl => {
        if(eleCrawl.exists) {
          var urlCrawl = ''
          urlCrawl = eleCrawl.data().WebsiteURL
          // console.log('first',eleCrawl.id)
          db.collection('Crawls').doc(eleCrawl.id).update({
            status: 'terminated',
            updated: Date.now(),
            dateEnd: Date.now()
          })
          .then(() => console.log('Update status crawl'))
          .catch(e => console.log('Error update crawl: ', e))

          db.collection('Sites').doc(eleCrawl.data().SiteID).update({
            mostRecentCrawlStatus: 'terminated',
            mostRecentCrawlDate: Date.now()
          })
          .then(() => console.log('Update status site crawl'))
          .catch(e => console.log('Error update site: ', e))

          db.collection('Sites_cache').doc(eleCrawl.data().SiteID).update({
            mostRecentCrawlStatus: 'terminated',
            mostRecentCrawlDate: Date.now()
          })
          .catch(e => console.log('Error update site: ', e))

          // write end crawl
          var configCrawl = {
            method: 'post',
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/end/crawl',
            maxBodyLength: Infinity,
            timeout: 0,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({idCrawl: eleCrawl.id})
          };
      
          this.axios(configCrawl)
          this.$emit('update:crawl-running', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Crawl for ${urlCrawl} has been cancelled`,
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
          this.$refs.sidebar_cancel_crawl.hide()
        }
      })
      .catch(e => {
        console.log('Error query crawl: ', e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error canceling crawl',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-archive-site {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
