<template>
  <section>
    <span
      class="banner_image md:w-1/2"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    />
    <div
      class="container"
    >
      <div>
        <h3>Sign up to take <span>screenshots <br> </span> every day</h3>
        <b-button
          class="button-banner mt-2"
          @click="$router.resolve(({ path: `/seocloud`}))"
        >
          Start now
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import bannerImage from '@/assets/images/banners/free-account-banner@2x.png'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      image: bannerImage,
    }
  },
}
</script>

<style scoped>
section{
    background: #F0F1F3;
    height: 410px;
    display: flex;
    position: relative;
}
img{
  height: 100%;
}
.container{
  max-width: 1280px; margin: auto;
}
h3,h3 span{
  font: normal normal bold 48px/48px "Hurme Geometric Sans 3" !important;
  margin: 0;
}
h3 span{
  color: #CA34FF;
}
.button-banner{
  font: normal normal bold 16px/34px "Hurme Geometric Sans 3" !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #CA34FF 0% 0% no-repeat padding-box!important;
  box-shadow: 0px 6px 9px #CA34FF26!important;
  border: none;
  border-radius: 28px;
  display: block
}
.banner_image{
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  right: 0;
}
.container{
  z-index: 1;
}
@media (max-width: 767px) {
  .banner_image{
    display: none;
  }
  .button-banner{
    margin: auto;
  }
  h3{
    text-align: center;
    margin-bottom: 4rem;
  }
}
</style>