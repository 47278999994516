<template>
    <div>
      <b-sidebar
        id="sidebar-right-redirections"
        ref="sidebar_create_redirection"
        :visible="showOpenRedirect"
        bg-variant="white"
        right
        backdrop
        shadow
        no-header
        @change="(val) => $emit('update:show-open-redirect', val)"
      >
        <template #default="{ hide }">
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
            style="background-color: #F0F1F7; height: 91px;"
          >
            <b-card
              no-body
              class="overflow-hidden w-100"
              style="background-color: #F0F1F7;"
            >
              <h1 style="margin-top: 30px;">Create redirection</h1>
            </b-card>
            <feather-icon
              class="cursor-pointer"
              icon="XIcon"
              size="20"
              @click="hide"
            />
          </div>
          <div
            class="b-sidebar-body mt-1"
            style="padding: 20px;"
          >
            <validation-observer ref="rulesForm">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Source URL"
                      class=""
                      style="font-weight: 700;"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source URL"
                        rules="required|url"
                      >
                        <b-form-input
                          v-model="source"
                          placeholder="Source URL"
                          style="font-weight: 400; border-radius: 10px; width: 100%;"
                          class=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <p class="text-danger mb-0" v-if="source && !source.includes('://'+hostname)" style="font-size: 0.857rem; font-weight: 400;">URL must to be internal</p>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Target URL"
                      class=""
                      style="font-weight: 700;"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Target URL"
                        rules="required|urlTarget"
                      >
                        <b-form-input
                          v-model="target"
                          placeholder="Target URL"
                          style="font-weight: 400; border-radius: 10px; width: 100%;"
                          class=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Comment"
                      class=""
                      style="font-weight: 700;"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Comment"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="comment"
                          placeholder="Comment"
                          style="font-weight: 400; border-radius: 10px; width: 100%;"
                          class=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px;"
                  class="mt-2"
                  variant="primary"
                  :disabled="processingSave || source && !source.includes('://'+hostname) ? true : false"
                  @click="validationForm"
                >
                  <span
                    class="align-middle"
                    style="font-weight: 700; color: #FFFFFF;"
                  >Save</span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
    BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import flatPickr from 'vue-flatpickr-component'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required, max, url, urlTarget } from '@validations'
  // import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { mapActions } from 'vuex'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import { db, auth } from '@/firebase'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormGroup,
      BFormTimepicker,
      BAvatar,
      BCard,
      BCardImgLazy,
      BCardText,
      BCol,
      BRow,
      BListGroup,
      BListGroupItem,
      BImgLazy,
      HeaderSidebar,
  
      // date
      flatPickr,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
    },
    model: {
      prop: 'showOpenRedirect',
      event: 'update:show-open-redirect',
    },
    props: ['showOpenRedirect', 'idsite', 'hostname'],
    data() {
      return {
        required,
        max,
        url,
        urlTarget,
  
        selectedMove: '',
        nameSite: '',
  
        agentesAsignados: '',
        userData: '',
        password: '',
        passwordConfirm: '',
        emailOld: '',
        showDateHour: false,
        selectedPermissions: [],
        permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
        allProject: [
          {
            label: 'My sites',
            color: '#F0F1F7',
          },
          {
            label: 'Facade interactive sites',
            color: '#F39C19',
          },
          {
            label: "Jane's sites",
            color: '#1CA085',
          },
        ],
        selectedProPage: '',
        optionsProPage: [
          'Home',
          'Blog',
          'Contact',
        ],
        selectedError: 'Missing Title',
        optionsErrors: [
          'Meta title',
          'Missing Title',
          'Missing descriptions',
          'Missing alts',
          'Headings H1',
          'Low word count',
          'External links',
        ],
        fixText: '',
  
        //   sources
        errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),

        // form
        target: '',
        source: '',
        comment: '',
        processingSave: false
      }
    },
    watch: {
      showOpenRedirect(val) {
        // this.nameSite = this.idsite.name
        this.processingSave = false
        this.target = ''
        this.source = ''
        this.comment = ''
        if(val) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      },
    },
    validations: {
    },
    methods: {
      submitEditar() {
        this.processingSave = true
        var user = auth.currentUser
        if(user && user.uid) {
          db.collection('Redirections').add({
            target: this.target,
            source: this.source,
            comment: this.comment,
            idsite: this.idsite,
            iduser: user.uid,
            status: 'active'
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Redirect created',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$refs.sidebar_create_redirection.hide();
            
            setTimeout(() => {
              this.processingSave = false
            }, 2500);
          })
        } else {
          this.processingSave = false
        }
      },
      validationForm() {
        // console.log(this.siteProject)
        this.$refs.rulesForm.validate().then(success => {
          if (success) {
            this.submitEditar()
          }
        })
      },
    },
    setup() {
      return {
        avatarText,
      }
    },
  }
  </script>
  
  <style>
  .b-sidebar-backdrop {
    background-color: #181E38BF !important;
    opacity: 1 !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  #sidebar-right-redirections {
      width: 90%;
      max-width: 593px !important;
  }
  // label {
  //   margin-bottom: 0px !important;
  //   height: 25px;
  // }
  // .col-form-label {
  //   margin-bottom: 0px !important;
  //   height: 25px;
  //   font-size: 0.857rem !important;
  // }
  // .form-control {
  //   height: 35px;
  // }
  // .v-select {
  //   height: 35px;
  //   margin-top: 0px;
  // }
  // .vs__dropdown-toggle {
  //   height: 35px;
  // }
  // .vs__selected {
  //   margin-top: 0px !important;
  // }
  </style>
  