<template>
  <b-sidebar
    id="screenshot-preview-sidebar-mobile"
    name="screenshot-preview-sidebar-mobile"
    :visible="isPreviewsSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:isPreviewsSidebarActive', val)"
  >
    <PreviewScreenshots
      v-if="previewToShow"
      :previews="previewToShow"
      :sidebarMode="true"
      @close="(event) => $emit('update:isPreviewsSidebarActive', event)"
    />
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import PreviewScreenshots from './PreviewScreenshots.vue'

export default {
  name: 'SidebarPreviewsScreenshotsMobile',
  components: {
    BSidebar, PreviewScreenshots,
  },
  props: {
    isPreviewsSidebarActive: {
      type: Boolean,
      required: true,
    },
    previewToShow: {
      type: Object,
      default: () => {},
    },
  },
}
</script>