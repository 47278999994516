<template>
  <div>
    <b-sidebar
      id="sidebar-right-responsive"
      ref="sidebar_info_responsive"
      :visible="showOpenResponsive"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-responsive', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="infoIdsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="pl-0 px-2 pt-75 pb-2" style="background: #f7f7f7; border-bottom: 1px solid #ebe9f1;">
          <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
            Page:
          </p>
          <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
            {{ dataResponsive.title }}
          </p>
          <a :href="dataResponsive.page" target="_blank">
            <span
              class="url-header-sidebar"
              style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
            >
              {{ dataResponsive.page }}
            </span>
          </a>
        </div>
        <div class="ml-3 my-2">
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100 mt-0"
          style="background-color: transparent;"
        >
          <b-row
            no-gutters
            class="mt-0 px-2"
          >
            <b-col
              md="12"
              class="m-auto"
            >
                <!-- <h4 style="" class="mt-50">Issue: <div v-html="dataResponsive.comment" style="font-weight: 300" class="content-comment-responsive-td"></div></h4> -->
                <p class="card-text mb-0 pt-50 name-site-header-sidebar " style="font-weight: 700; color: #181E38; font-size: 15px;">
                  Status: <b-badge style="font-size: 13px;" :variant="dataResponsive.status === 'Error' ? 'info' : 'success'">
                  {{dataResponsive.status === 'Error' ? 'In progress' : 'Fixed'}}
                </b-badge>
                </p>
                <p class="card-text mb-0 pt-0 name-site-header-sidebar mt-1" style="font-weight: 700; color: #181E38; font-size: 15px;">
                  Issue:
                </p>
                <div v-html="dataResponsive.comment" style="font-weight: 300; font-size: 15px; color: #181E38;" class="content-comment-responsive-td pt-50"></div>

                <!-- v-if="type === 'img'" -->
                <b-img-lazy
                    fluid
                    :src="dataResponsive.image"
                    alt="img"
                    loading="lazy"
                    class="mt-75"
                    style="border-radius: 3px !important; object-fit: cover !important; width: auto; height: auto; max-width: 100%;"
                    onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/check.svg?alt=media&token=d3570428-5403-4285-a4bb-1b9faa4c6485';this.className='imgOnErr';"
                />
                <!-- <h4 style="" class="mt-1">Status:  -->
                  <!-- <span :style="dataResponsive.status === 'Error' ? 'color: #f77a54': 'color: #6fd95f'">{{dataResponsive.status}}</span> -->
                
                <!-- </h4> -->
            </b-col>
          </b-row>
        </b-card>
        
        <!-- <help-footer /> -->
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BBadge,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import moment from "moment";
import store from '@/store'
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BBadge,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenResponsive',
    event: 'update:show-open-responsive',
  },
  props: ['idsite','showOpenResponsive','dataResponsive','type'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,
      userData: '',

      planType: '',
      planSite: '',
      paidRecurrency: '',
      nextDue: '',
      nextDueCancel: '',
      cancelSub: '',
      subscriptionId: '',
      pages: '',
      infoIdsite: '',

      //   sources
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
    }
  },
  watch: {
    showOpenResponsive(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
        // db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.$route.params.id).get()
        // .then(site => {
        //     if(site.exists) {
        //         this.planType = site.data().planType ? parseInt(site.data().planType, 10) : ''
        //         this.planSite = site.data().plan ? site.data().plan : ''
        //         this.paidRecurrency = site.data().paidRecurrency ? site.data().paidRecurrency : ''
        //         this.nextDue = site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-'
        //         this.nextDueCancel = site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-'
        //         this.cancelSub = site.data().cancelSub ? site.data().cancelSub : false
        //         this.subscriptionId = site.data().subscriptionId ? site.data().subscriptionId : ''
        //         this.pages = site.data().planType ? site.data().planType : ''
        //     }
        // })

        // console.log(this.idsite);
        if(this.idsite && this.idsite.id) {
          this.infoIdsite = this.idsite
          this.infoIdsite.domain = this.dataResponsive.page
          this.infoIdsite.domainFix = this.dataResponsive.page
          // this.infoIdsite.imgSite = this.dataResponsive.image
        }
      } else {
        document.body.style.overflow = 'auto'
        this.infoIdsite = ''
      }
    },
    // idsite(val) {
      
    // }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_info_responsive.hide()
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-responsive {
    width: 90%;
    max-width: 593px !important;
}
</style>