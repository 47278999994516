<template>
  <div class="shareScreenshots">
    <customHeader
      :page-title="headTitle"
      :url="$route.params.id ? 'app.seocloud.ai/share?site='+($route.params.id).replaceAll('_', '.') : ''"
      :url-text="$route.params.id ? ($route.params.id).replaceAll('_', '.') : ''"
    />
    <div
      v-if="dataRetrieved.show"
      class="w-full py-2 px-2 container"
    >
      <div class="w-full text-right mt-1 btn-share-mobile hidden">
        <b-button
          variant="outline-primary"
          class="share"
          @click="shareLink"
        >
          <template v-if="!copied">
            <feather-icon
              icon="Share2Icon"
              class="inline"
            />
            Share
          </template>
          <template v-else>
            Copied!
          </template>
        </b-button>
        <b-button
          class="btn-icon mr-1 takeScreenshotNowBtn"
          @click="$router.resolve(({ path: `/login`}))"
        >
          <feather-icon
            icon="CameraIcon"
            class="inline-block"
          /> Take Screenshot Now
        </b-button>
      </div>
      <header class="flex">
        <div class="w-full md:w-6/12">
          <PreviewsTitle
            :url="dataRetrieved.rawUrl"
            :comment="dataRetrieved.comment"
            :date="imagesList.date"
          />
        </div>
        <div class="w-6/12 text-right mt-1 btn-share">
          <b-button
            class="btn-icon mr-1 takeScreenshotNowBtn"
            @click="$router.resolve(({ path: `/login`}))"
          >
            <feather-icon
              icon="CameraIcon"
              class="inline-block"
            /> Take Screenshot Now
          </b-button>
          <b-button
            variant="outline-primary"
            class="share btn-icon"
            @click="shareLink"
          >
            <template>
              <feather-icon
                :icon="copied ? 'CopyIcon' : 'Share2Icon'"
                class="inline"
              />
              {{ copied ? 'Copied!' : 'Share' }}
            </template>
          </b-button>

        </div>
      </header>
      <div class="block md:flex mt-16 screenshots-shared-preview">
        <div class="mb-2 text-center w-full md:w-4/12">
          <img
            :src="imagesList.desktop"
            alt="Desktop"
            loading="lazy"
            @click="showScreenshot('desktop')"
          ><p>Desktop</p>
        </div>
        <div class="mb-2 text-center w-full md:w-2/12 screenshotTablet">
          <img
            :src="imagesList.tablet"
            alt="Tablet"
            loading="lazy"
            @click="showScreenshot('tablet')"
          ><p>Tablet</p>
        </div>
        <div class="mb-2 text-center  w-full screenshotPhone">
          <img
            :src="imagesList.mobile"
            alt="Mobile"
            loading="lazy"
            @click="showScreenshot('mobile')"
          ><p>Mobile</p>
        </div>
      </div>
      <div
        v-if="totalErrors > 0"
        class="text-center"
      >
        <b-button
          class="btn-icon my-1 takeScreenshotNowBtn btn-fix-errors"
          @click="goHomepage"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            size="17"
            class="inline-block"
          /> Your site has {{ totalErrors }}+ errors click here to fix them
        </b-button>
      </div>
    </div>
    <Banner1 />
    <Banner2 />
    <previewSidebar
      name="previewSidebar"
      :is-previews-sidebar-active.sync="isPreviewSidebarActive"
      :images="imagesList"
      :type-device="type"
    />
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import customHeader from './banners/Header.vue'
import Banner1 from './banners/ShareScreenshots_D1.vue'
import Banner2 from './banners/ShareScreenshots_D2.vue'
import screenshotsRepository from './services'
import functions from './components/screenshotsFunctions'
import previewSidebar from './components/SidebarPreviews.vue'
import PreviewsTitle from './components/PreviewsTitle.vue'

export default {
  name: 'SharedScreenshots',
  components: {
    Banner1, Banner2, BButton, customHeader, previewSidebar, PreviewsTitle,
  },
  data() {
    return {
      dataRetrieved: {
        url: '', comment: '', show: false, rawUrl: '',
      },
      headTitle: 'Shared Screenshot',
      title: '',
      type: 'desktop',
      imageToModal: '',
      copied: false,
      isPreviewSidebarActive: false,
      totalErrors: -1,
    }
  },
  computed: {
    imagesList() {
      const images = {
        mobile: `https://storage.googleapis.com/screenshots_bucket-seocloud/${this.$route.query.page}/mobile/${this.$route.query.key}.webp`,
        tablet: `https://storage.googleapis.com/screenshots_bucket-seocloud/${this.$route.query.page}/tablet/${this.$route.query.key}.webp`,
        desktop: `https://storage.googleapis.com/screenshots_bucket-seocloud/${this.$route.query.page}/desktop/${this.$route.query.key}.webp`,
        date: functions.generateDateString(Number(this.$route.query.key), true),
        comment: this.dataRetrieved.comment,
        url: this.dataRetrieved.rawUrl,
      }
      return images
    },
  },
  async created() {
    if (this.$route.params.id && this.$route.query.page && this.$route.query.key) {
      try {
        const response = await screenshotsRepository.actions.getInfoShare(this.$route.params.id, this.$route.query.page, this.$route.query.key)
        const resProPages = await screenshotsRepository.actions.getPages(response.result.url)
        this.totalErrors = resProPages.totalErrors
        this.dataRetrieved.comment = response.result.comment.startsWith('_API_') ? 'Scheduled Screenshot' : response.result.comment
        this.dataRetrieved.url = functions.removeProtocolFromURL(response.result.url)
        this.dataRetrieved.rawUrl = response.result.url
        this.dataRetrieved.show = true
      } catch (error) {
        this.headTitle = 'Screenshot not found'
      }
    } else {
      this.headTitle = 'Screenshot not found'
    }
  },
  methods: {
    showScreenshot(t) {
      this.type = t
      this.isPreviewSidebarActive = true
    },
    shareLink() {
      navigator.clipboard.writeText(document.baseURI)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    goHomepage() {
      const home = this.$route.params.id
      this.$router.push(({ path: '/seocloud-share', query: { site: home.replaceAll('_', '.') } }))
    },
  },
}
</script>

<style lang="scss">
body{
  background-color: #F9F9F9;
}
.container{
  max-width: 1280px; margin: auto;
}

// h1{
//   font: normal normal bold 32px/52px "Hurme Geometric Sans 3";
// }
.screenshots-shared-preview{
  p{
    @apply mt-6;
  }
  img {
    height: auto;
    min-height: 256px;
    max-height: 256px;
    object-fit: cover;
    object-position: top;
    width: 100%;
    cursor: pointer;
  }
}
.screenshotPhone{
  max-width: 145px;
  margin-left: 3rem;
}
.screenshotTablet{
  width: 230px;
  margin-left: 3rem;
}
@media (max-width: 767px) {
  .btn-share{
    display: none;
  }
  .btn-share-mobile{
    display: block;
    visibility: visible;
  }
  .screenshotTablet, .screenshotPhone{
    margin: auto!important;
  }
}
.btn-fix-errors{
  font-size: 24px;
  padding: 16px!important;
}
.takeScreenshotNowBtn{
    background: transparent linear-gradient(90deg, #CA34FF 0%, #7838FF 100%) 0% 0% no-repeat padding-box;
    line-height: 14px;
    font-size: 16px;
    border: none;
    &:hover{
        box-shadow: 0 0 5px 0 #CA34FF!important;
    }
}
</style>