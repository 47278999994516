<template>
  <div>
    <info-responsive
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-responsive.sync="showOpenResponsive"
      :data-responsive.sync="dataResponsive"
      :type.sync="typeSelectedResponsive"
    />
    <div class="d-flex mt-75" style="border: 1px solid rgb(236, 236, 236); padding: 10px; border-radius: 10px;">
        <b-col
            md="12"
            class="mt-25"
          >
            <h1>Responsive errors</h1>
            <h2
              class="float-left mb-0 w-100"
              style="font-weight: 700; color: rgb(252, 125, 125) !important; text-align: left; font-size: 28px !important;"
            >
              <b-spinner
                class="mt-50 mr-1"
                style="color: #bbb !important;"
                v-if="loading"
              />
              <span v-else>{{ counter }} </span>
              <span style="font-weight: initial; color: #656B85; font-size: 1rem !important;">errors</span>
            </h2>
            <span v-if="loading">In <b-spinner
                class="mt-50 mr-0"
                style="color: #bbb !important;"
                small
                v-if="loading"
              /> pages</span>
            <span v-else>{{`In ${selectedFilteredPages.length && itemsPagesOverview.length !== selectedFilteredPages.length ? selectedFilteredPages.length : data.length} pages`}}</span>
        </b-col>
    </div>
    <slot name="top"></slot>
    <b-row class="mt-2 tabs-responsive">
      <b-col md="12">
        <div style="text-align: center;" v-if="loading">
          <b-spinner
            class="mt-2"
            style="color: #bbb !important;"
          />
        </div>
        <div class="top-scroll-horizontal-tabs-responsive" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style=" height: 20px;"></div></div>
        <b-table-simple
            small
            responsive
            id="tab-err-sc1"
            class="vgt-table tableErrors"
            :style="'margin-bottom: 60px !important;'"
            v-if="arrayInfiniteData.length >= 1 && !loading"
        >
            <b-thead>
                <b-tr>
                    <b-th class="thProTableErrors" style="max-width: 82px !important; width: 82px !important; min-width: 82px !important;">AI</b-th>
                    <b-th class="thPageTableErrors">Page</b-th>
                    <b-th class="thStatusTableErrors">Status</b-th>
                    <b-th class="thImgTableErrors">Image</b-th>
                    <b-th class="thTableResponsiveIssue">Issue</b-th>
                    <b-th class="">Action</b-th>
                </b-tr>
            </b-thead>
            <b-tbody v-if="arrayInfiniteData.length === 0">
                <b-tr>
                <b-td>
                    No pages
                </b-td>
                </b-tr>
            </b-tbody>
            <b-tbody v-for="(dataList, index) in selectedFilteredPages.length && itemsPagesOverview.length !== selectedFilteredPages.length ? arrayInfiniteDataFilter : arrayInfiniteData" :key="index" :style="index % 2 ? 'background: #fff !important;' : 'background: rgb(240, 241, 243) !important;'" v-else>
                  <b-tr v-for="(data, indexList) in dataList.list" :key="indexList">
                    <b-th :rowspan="dataList.list.length" v-if="indexList === 0" class="tdProTableErrors" style="vertical-align: top !important; max-width: 82px !important; width: 82px !important; min-width: 82px !important;">
                        <b-button
                            :style="'background: transparent !important; border: 0px; position: absolute !important; left: -11px !important; margin-top: 5px; display: inline !important;'"
                            target="_blank"
                            :href="data.page"
                            class="p-0 ml-75 eyeBtns linkURL pl-1"
                        >
                            <feather-icon
                                icon="ExternalLinkIcon"
                                class="cursor-pointer eyeBtns"
                                style="color: #656b85 !important;"
                                size="20"
                            />
                        </b-button>
                        <b-img-lazy
                            v-if="pros.includes(data.page)"
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class="mt-50"
                            style="margin-left: 50px !important;"
                            loading="lazy"
                        />
                        <b-img-lazy
                            v-else
                            fluid
                            :src="proIconNone"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class="mt-50"
                            style="margin-left: 50px !important;"
                            loading="lazy"
                        />
                    </b-th>
                    <b-th :rowspan="dataList.list.length" v-if="indexList === 0" class="tdPageTableErrors" style="vertical-align: top !important;">
                        <span class="ml-0 mt-50" style="font-weight: 400 !important; font-size: 14px !important; display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.page ? data.page : data.page }}</span><br>
                    </b-th>
                    <b-td class="tdStatusTableErrors">
                        <feather-icon
                            v-if="data.status === 'Error'"
                            icon="XIcon"
                            class="mr-50"
                            :style="'color: #FC7D7D'"
                            size="20"
                        />
                        <feather-icon
                            v-else
                            icon="CheckIcon"
                            class="mr-50"
                            :style="'color: #28c76f !important'"
                            size="20"
                        />
                    </b-td>
                    <b-td class="tdImgTableErrors" :style="'color: #656b85 !important; cursor: pointer;'" @click.prevent="typeSelectedResponsive = 'img', showOpenResponsive = true, dataResponsive = data">
                        <b-img-lazy
                            fluid
                            :src="data.image"
                            alt="img"
                            loading="lazy"
                            class="mt-25"
                            style="border-radius: 3px !important; object-fit: cover !important; width: 92px; height: 72px;"
                            onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/check.svg?alt=media&token=d3570428-5403-4285-a4bb-1b9faa4c6485';this.className='imgOnErr';"
                        />
                    </b-td>
                    <b-td class="tdTableResponsiveIssue" style="cursor: pointer;" @click.prevent="typeSelectedResponsive = 'comment', showOpenResponsive = true, dataResponsive = data">
                      <div v-html="data.comment" class="content-comment-responsive-td"></div>
                    </b-td>
                    <b-td class="" style="cursor: pointer;">
                      <!-- <span>{{ data.status === 'Fixed' ? 'Fixed' : 'In progress' }}</span> -->
                      <b-badge :variant="data.status === 'Error' ? 'info' : 'success'">
                        {{data.status === 'Error' ? 'In progress' : 'Fixed'}}
                      </b-badge>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BTab, BCard, BCardHeader, BRow, BCol, BCardText, BCardBody, BAvatar, BTabs, BTd, BTbody, BTableSimple, BThead, BImgLazy, BTh, BTr, BBadge, BSpinner
} from 'bootstrap-vue'
import InfoResponsive from '@/views/extensions/acl/table/sidebar/InfoResponsive.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'Performance',
  components: {
    BButton,
    BTabs,
    BTab,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BAvatar,
    BTd,
    BTbody,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BImgLazy,
    BBadge,
    BSpinner,
    VueApexCharts,
    InfoResponsive,
  },
  props: ['active','data','pros','siteObj','counter','selectedFilteredPages','itemsPagesOverview'],
  data() {
    return {
        loading: true,
        proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
        proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),
        Mobile: true,
        Desktop: false,
        History: false,
        showOpenResponsive: false,
        typeSelectedResponsive: '',
        dataResponsive: '',
        arrayInfiniteData: [],
        timerInfinite: null,
        loadingInfiniteScroll: false,
        arrayInfiniteDataFilter: [],
    }
  },
  watch: {
    // active(val) {
    //   if(val) {
    //     history.pushState(null, "", `?section=responsive`)      
    //   }
    // }
    data(val) {
      // console.log(val.length);
      if(val && val.length) {
        var counter = 0
        val.forEach(element => {
          counter += 1
          if(counter <= 3) {
            this.arrayInfiniteData.push(element)
          }
        });

        setTimeout(() => {
          this.loadingInfiniteScroll = false
        }, 1000)
      }
    },
    selectedFilteredPages(val) {
      if(val.length && this.itemsPagesOverview.length !== val.length) {
        this.arrayInfiniteDataFilter = []
        this.data.forEach(item => {
          var filterD = val.filter(ele => ele.cUrl === item.page)
          var filterArryF = this.arrayInfiniteDataFilter.filter(ele => ele.cUrl === item.page)
          if(filterD.length && !filterArryF.length) {
            this.arrayInfiniteDataFilter.push(item)
          }
        })
      }
    }
  },
  methods: {
    infiniteScroll() {
      var listElm = document.querySelector('#app');
      let bottomOfWindow = document.documentElement.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 200
      // if(this.selectedFilteredPages.length === 0) {
        if(this.data.length >= 1 && this.active) {
          if(bottomOfWindow) {
            if(!this.loadingInfiniteScroll && this.data.length !== this.arrayInfiniteData.length) {
              // console.log('first')
              this.loadingInfiniteScroll = true
              var counterFound = 0
              var arrayInfinite = []
              this.data.forEach(ele => {
                var alreadyAdd = arrayInfinite.filter(item => item.idPageOverview === ele.idPageOverview && item.page === ele.page)
                if(alreadyAdd.length === 0) {
                  if(counterFound <= 10) {
                    counterFound += 1
                    arrayInfinite.push(ele)
                  }
                }
              })
              this.arrayInfiniteData = arrayInfinite

              setTimeout(() => {
                this.loadingInfiniteScroll = false
              }, 1000)
            }
          }
        }
      // }
    }
  },
  mounted() {
    this.timerInfinite = setInterval(() => {
      this.infiniteScroll()
    }, 600)
  },
  destroyed() {
    clearInterval(this.timerInfinite)
  },
  created() {
    this.loading = true
    this.arrayInfiniteData = []
    this.timerInfinite = null
    this.loadingInfiniteScroll = true
    setTimeout(() => {
      this.loading = false
    }, 4500)
  }
}
</script>

<style>
.content-comment-responsive-td p {
  border-bottom: 10px !important;
  margin-bottom: 0px !important;
}
.thTableResponsiveIssue {
  min-width: 160px;
}
.tdTableResponsiveIssue {
  min-width: 160px;
}
</style>