<template>
  <div>
    <b-sidebar
      id="sidebar-right-create-alert"
      ref="sidebar_create_alert"
      :visible="showOpenCreateAlert"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-create-alert', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100 mt-0"
          style="background-color: transparent;"
        >
          <b-row
            no-gutters
            class="mt-0 mx-1"
          >
            <b-col
              md="12"
              class="m-auto"
            >
                <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="creatingAlert ? 'outline-warning' : 'info'"
                    class="btn-icon float-right"
                    style="padding: 5px"
                    @click.prevent="creatingAlert ? creatingAlert = false : creatingAlert = true"
                >
                    <feather-icon :icon="creatingAlert ? 'XIcon' : 'PlusIcon'" size="22" />
                </b-button> -->
                <h4 class="ml-1 mt-1">Alerts</h4>
                <b-overlay
                    id="overlay-background"
                    :show="loadingData"
                    :variant="'transparent'"
                    :opacity="0.80"
                    :blur="'2px'"
                    rounded="sm"
                    style="min-height: 650px;"
                >
                    <div v-if="creatingAlert">
                        <validation-observer ref="simpleRules">
                            <b-form>
                                <b-row class="mx-1">
                                    <b-col md="12" class="mx-0 px-0 mt-75">
                                        <b-form-group>
                                            <template v-slot:label>
                                                Page <span class="text-danger">*</span>
                                            </template>
                                            <validation-provider
                                                name="Page"
                                                rules="required"
                                                #default="{ errors }"
                                            >
                                                <b-form-select
                                                    v-model="pageUrl"
                                                    :options="optionsPages"
                                                    class="create-alert-frequency-select-form"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="12" class="px-0 mt-75">
                                        <div>
                                            <b-form-group>
                                            <template v-slot:label>
                                                <span class="ml-3">Frequency</span> <span class="text-danger">*</span>
                                            </template>
                                            <validation-provider
                                                name="Frequency"
                                                rules="required"
                                                #default="{ errors }"
                                            >
                                                <div class="d-flex">
                                                    <feather-icon
                                                      icon="CalendarIcon"
                                                      size="26"
                                                      class="mt-75 mr-1"
                                                    />
                                                    <b-dropdown
                                                      :text="frequency"
                                                      :style="'cursor: pointer !important;'"
                                                      variant="outline-primary"
                                                      class="frequency-create-alert style-chooser mb-25 ml-25"
                                                      :class="'style-chooser mb-25 mt-1'"
                                                    >
                                                      <template #button-content>
                                                        <span class="">{{ frequency }}</span>
                                                      </template>
                                                      <b-dropdown-item style="width: 130px;"
                                                        @click.prevent="frequency = 'Daily'"
                                                      >
                                                        <span style="font-size: 15px;">
                                                        Daily
                                                        </span>
                                                      </b-dropdown-item>
                                                      <b-dropdown-item style="width: 130px;" @click.prevent="frequency = 'On a week'">
                                                        <span style="font-size: 15px;">
                                                          On a week
                                                        </span>
                                                      </b-dropdown-item>
                                                      <!-- <b-dropdown-item style="width: 130px;" @click.prevent="frequency = 'Monthly'">
                                                        <span style="font-size: 15px;">
                                                          Monthly
                                                        </span>
                                                      </b-dropdown-item> -->
                                                    </b-dropdown>
                                                </div>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                    <!-- <b-row> -->
                                        <b-col md="6" class="pl-0 mt-75">
                                            <b-form-group class="w-100">
                                                <template v-slot:label>
                                                    When <span class="text-danger">*</span>
                                                </template>
                                                <validation-provider
                                                    name="When"
                                                    rules="required"
                                                    #default="{ errors }"
                                                >
                                                    <b-form-select
                                                        v-model="whenCondition"
                                                        :options="optionsWhenCondition"
                                                        class="create-alert-frequency-select-form w-100"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" class="pr-0 mt-75">
                                            <b-form-group class="w-100">
                                                <template v-slot:label>
                                                    {{whenCondition === 'Performance is higher than' || whenCondition === 'Performance is lower than' ? 'Percentage' : 'Seconds'}} <span class="text-danger">*</span>
                                                </template>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="whenCondition === 'Performance is higher than' || whenCondition === 'Performance is lower than' ? 'Percentage' : 'Seconds'"
                                                    rules="required|between:1,100"
                                                >
                                                    <div class="d-flex">
                                                    <b-form-input
                                                        v-model="conclusionCondition"
                                                        :placeholder="whenCondition === 'Performance is higher than' || whenCondition === 'Performance is lower than' ? '0' : '0'"
                                                        :state="errors.length > 0 ? false:null"
                                                        class="w-100"
                                                    />
                                                    <span style="font-size: 22px; margin-left: 5px;">
                                                    {{whenCondition === 'Performance is higher than' || whenCondition === 'Performance is lower than' ? '%' : 's'}}
                                                    </span>
                                                    </div>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    <!-- </b-row> -->
                                    <b-col cols="12" style="text-align: right;">
                                        <b-button
                                            variant="primary"
                                            type="submit"
                                            :disabled="loadingCreateAlert"
                                            @click.prevent="validationForm"
                                        >
                                            Create
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </div>
                    <table style="border: 0px; overflow-x: auto; display: block; width: 100%;" class="h-100" v-else>
                      <thead style="border: 0px;">
                        <th style="max-width: 150px; color: #c1c1c1; border: 0px; padding: 10px 20px; width: 150px; color: #A7AAB9 !important; font-weight: 300; font-size: 17px !important; text-transform: capitalize !important;">Type</th>
                        <th style="max-width: 150px; color: #c1c1c1; border: 0px; padding: 10px 20px; width: 150px; color: #A7AAB9 !important; font-weight: 300; font-size: 17px !important; text-transform: capitalize !important;">Device</th>
                        <th style="max-width: 300px; color: #c1c1c1; border: 0px; padding: 10px 20px; width: 150px; color: #A7AAB9 !important; font-weight: 300; font-size: 17px !important; text-transform: capitalize !important;">When</th>
                        <th style="max-width: 200px; color: #c1c1c1; border: 0px; padding: 10px 20px; width: 150px; color: #A7AAB9 !important; font-weight: 300; font-size: 17px !important; text-transform: capitalize !important;">Frequency</th>
                      </thead>
                      <tbody style="border: 0px;">
                        <tr v-for="(item, index) in performanceArray" :key="index" style="border: 0px;">
                          <td style="border: 0px; padding: 10px 20px; width: 150px;">
                            <b-badge variant="light-secondary" style="background: #eee; color: #737373 !important; border-radius: 15px; font-size: 14px;">
                              {{item.type}}
                            </b-badge>
                          </td>
                          <td style="border: 0px; padding: 10px 20px; width: 150px;">
                            <b-badge :variant="item.device === 'Desktop' ? 'light-primary' : 'light-info'" style="border-radius: 15px; font-size: 14px;">
                              {{item.device}}
                            </b-badge>
                          </td>
                          <td style="border: 0px; padding: 10px 20px; width: 300px;">
                            <span style="color: #737373 !important">{{item.condition}} <span style="color: #7637fb !important; padding: 2px; border: 1px solid #7637fb !important; border-radius: 3px; background: #7637fb26 !important; cursor: pointer !important;" @click.prevent="editConclusion ? (editConclusion = false, editConclusionId = '') : (editConclusion = true, editConclusionId = item.id)">{{item.conclusion}}{{item.condition.includes('Load') ? 's' : '%'}}</span></span>
                            <vue-slider
                              v-model="item.conclusion"
                              :direction="direction"
                              class="mb-2 mt-50 slider-custom-plan"
                              style="width: 85%;"
                              :marks="false"
                              :adsorb="true"
                              :min="item.condition && item.condition.includes('Load') ? 2 : 10"
                              :max="item.condition && item.condition.includes('Load') ? 12 : 90"
                              @drag-start="onDragStart"
                              @drag-end="onDragEnd"
                              @change="onChange"
                              v-if="editConclusion && item.id === editConclusionId"
                            />
                          </td>
                          <td style="border: 0px; padding: 10px 20px; width: 200px;">
                            <div class="d-flex">
                              <!-- <feather-icon
                                icon="CalendarIcon"
                                size="26"
                                class="mt-75"
                              /> -->
                              <b-dropdown
                                :text="item.frequency"
                                :style="'cursor: pointer !important;'"
                                variant="outline-primary"
                                class="frequency-create-alert style-chooser mb-25 ml-25"
                                :class="'style-chooser mb-25 mt-25'"
                              >
                                <template #button-content>
                                  <div style="vertical-align: middle; display: inline-flex;">
                                    <feather-icon
                                      icon="CalendarIcon"
                                      size="26"
                                      class="mt-0"
                                    />
                                    <span style="padding-left: 10px !important; padding-top: 6px !important;">{{ item.frequency }}</span>
                                  </div>
                                </template>
                                <b-dropdown-item style="width: 130px;"
                                  @click.prevent="item.frequency === 'Daily' ? item.frequency = 'Daily' : item.frequency = 'Daily', submitUpateAutomate()"
                                >
                                  <span style="font-size: 15px;">
                                  Daily
                                  </span>
                                </b-dropdown-item>
                                <b-dropdown-item style="width: 130px;" @click.prevent="item.frequency === 'On a week' ? item.frequency = 'On a week' : item.frequency = 'On a week', submitUpateAutomate()">
                                  <span style="font-size: 15px;">
                                    On a week
                                  </span>
                                </b-dropdown-item>
                                <!-- <b-dropdown-item style="width: 130px;" @click.prevent="item.frequency === 'Monthly' ? item.frequency = 'Monthly' : item.frequency = 'Monthly', submitUpateAutomate()">
                                  <span style="font-size: 15px;">
                                    Monthly
                                  </span>
                                </b-dropdown-item> -->
                              </b-dropdown>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-row>
                      <b-col cols="12" style="text-align: right;">
                        <b-button
                          variant="primary"
                          type="submit"
                          class="mr-1 mt-1"
                          :disabled="loadingUpdateAlert"
                          @click.prevent="submitUpate"
                        >
                          Save
                        </b-button>
                      </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
          </b-row>
        </b-card>
        
        <help-footer />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BBadge, BOverlay,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BFormSelect,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email, between } from '@validations'
// import {
//   required, minLength, password, confirmed, email,
// } from 'vuelidate/lib/validators'
import VueSlider from 'vue-slider-component'
import storeInd from '@/store/index'
import 'vue-slider-component/theme/default.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import moment from "moment";
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BBadge,
    BFormSelect,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    HelpFooter,
    HeaderSidebar,
    VueSlider,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenCreateAlert',
    event: 'update:show-open-create-alert',
  },
  props: ['idsite','showOpenCreateAlert','dataResponsive','type','pages200'],
  data() {
    return {
    //   email,
      required,
      between,
    //   password,
    //   confirmed,
      userData: '',
      dir: 'ltr',
      editConclusion: false,
      editConclusionId: '',

      planType: '',
      planSite: '',
      paidRecurrency: '',
      nextDue: '',
      nextDueCancel: '',
      cancelSub: '',
      subscriptionId: '',
      pages: '',
      infoIdsite: '',
      performanceArray: [],
      optionsFrequency: [
        'Daily',
        'On a week',
        // 'Monthly'
      ],
      loadingData: false,
      idSitePerformance: '',
      performanceArraySave: [],
      loadingSaveAutomated: false,
      loadingOpen: false,

      //  create alert
      creatingAlert: false,
      loadingCreateAlert: false,
      loadingUpdateAlert: false,
      frequency: 'On a week',
      whenCondition: '',
      conclusionCondition: null,
      pageUrl: '',
      optionsPages: [],
      optionsWhenCondition: [
        'Load time is higher than',
        'Load time is lower than',
        'Performance is higher than',
        'Performance is lower than',
      ],

      //   sources
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
      isDragging: false,
    }
  },
  computed: {
    direction() {
      if(storeInd.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    // performanceArray(val) {
    //   console.log(val)
    // },
    showOpenCreateAlert(val) {
      if(val) {
        this.editConclusion = false
        this.editConclusionId = ''
        document.body.style.overflow = 'hidden'
        // db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.$route.params.id).get()
        // .then(site => {
        //     if(site.exists) {
        //         this.planType = site.data().planType ? parseInt(site.data().planType, 10) : ''
        //         this.planSite = site.data().plan ? site.data().plan : ''
        //         this.paidRecurrency = site.data().paidRecurrency ? site.data().paidRecurrency : ''
        //         this.nextDue = site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-'
        //         this.nextDueCancel = site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-'
        //         this.cancelSub = site.data().cancelSub ? site.data().cancelSub : false
        //         this.subscriptionId = site.data().subscriptionId ? site.data().subscriptionId : ''
        //         this.pages = site.data().planType ? site.data().planType : ''
        //     }
        // })

        // console.log(this.idsite);
        // if(this.idsite && this.idsite.id) {
        //   this.infoIdsite = this.idsite
        //   this.infoIdsite.domain = this.dataResponsive.page
        //   this.infoIdsite.domainFix = this.dataResponsive.page
        //   // this.infoIdsite.imgSite = this.dataResponsive.image
        // }
        var user = auth.currentUser
        this.loadingData = true
        this.creatingAlert = false
        this.loadingCreateAlert = false
        this.loadingUpdateAlert = false
        this.performanceArray = []
        this.performanceArraySave = []
        this.loadingSaveAutomated = true
        this.loadingOpen = true
        setTimeout(() => {
          this.loadingSaveAutomated = false
          this.loadingOpen = false
        }, 1500)

        if(user && user.uid) {
          db.collection('Users').doc(user.uid).get()
          .then(docUser => {
            db.collection('Performance').where('idsite', '==', this.idsite.id).where('userid', '==', user.uid).where('projectid', '==', this.idsite.projectid).get()
            .then(docsPerformace => {
              if(docsPerformace.size) {
                  var arrayAut = []
                  docsPerformace.forEach(ele => {
                    this.idSitePerformance = ele.id

                    var count = 0
                    ele.data().data.forEach(eleItem => {
                      // count += 1
                      if(eleItem.condition.includes('Performance')) {
                        if(eleItem.device === 'Mobile') {
                          count = 1
                        } else {
                          count = 2
                        }
                      } else {
                        if(eleItem.device === 'Mobile') {
                          count = 3
                        } else {
                          count = 4
                        }
                      }

                      this.performanceArray.push({
                        id: count,
                        type: 'Performance',
                        device: eleItem.device,
                        condition: eleItem.condition,
                        conclusion: eleItem.conclusion,
                        frequency: eleItem.frequency,
                        page: eleItem.page
                      })

                      arrayAut.push({
                        id: count,
                        type: 'Performance',
                        device: eleItem.device,
                        condition: eleItem.condition,
                        conclusion: eleItem.conclusion,
                        frequency: eleItem.frequency,
                        page: eleItem.page
                      })
                    })
                  })
                  this.performanceArray.sort(function (a, b) {
                    if(a.id > b.id) {
                      return 1;
                    }
                    if(a.id < b.id) {
                      return -1;
                    }
                    return 0;
                  });

                  arrayAut.sort(function (a, b) {
                    if(a.id > b.id) {
                      return 1;
                    }
                    if(a.id < b.id) {
                      return -1;
                    }
                    return 0;
                  });
                  this.performanceArraySave = arrayAut
                  this.loadingData = false
              } else {
                  this.performanceArray = [
                    {type: 'Performance', device: 'Mobile', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Mobile', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                  ]
                  this.performanceArraySave = [
                    {type: 'Performance', device: 'Mobile', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Mobile', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                  ]
                  this.loadingData = false

                  var performanceArrayAdd = [
                    {type: 'Performance', device: 'Mobile', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Performance is lower than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Mobile', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                    {type: 'Performance', device: 'Desktop', condition: 'Load time is higher than', conclusion: 10, frequency: 'Daily', page: ''},
                  ]

                  db.collection('Performance').add({
                    idsite: this.idsite.id,
                    projectid: this.idsite.projectid,
                    userid: user.uid,
                    userEmail: docUser.data().email,
                    // page: this.pageUrl,
                    // condition: this.whenCondition,
                    // conclusion: conclusion,
                    // frequency: this.frequency,
                    // type: 'Performance',
                    data: performanceArrayAdd
                  })
                  .then((docRef) => {
                    this.idSitePerformance = docRef.id
                    console.log('register perfomance');
                  })
              }

              
              this.pages200.forEach(ele => {
                if(!this.optionsPages.includes(ele.cUrl)) {
                    this.optionsPages.push(ele.cUrl)
                }
              })
            })
          })
        }

        // db.collection('Performance').get()
        // .then(docsAlerts => {
        //   if(docsAlerts.size) {
        //     var arrayAlerts = []
        //     docsAlerts.forEach(doc => {
        //       db.collection('Users').where('email', '==', doc.data().userEmail).limit(1).get()
        //       .then(qUseName => {
        //           var nameUser = ''
        //           if(qUseName.size) {
        //               qUseName.forEach(docUser => {
        //                   nameUser = docUser.data().nombre ? docUser.data().nombre : doc.data().userEmail
        //               })
        //           }

        //           // var lastNotification = doc.data().lastNotification
        //           var filterAlertExludePerformance = doc.data().data.filter(ele => !ele.condition.includes('Performance is'))
        //           var filterAlertPerformance = doc.data().data.filter(ele => ele.condition.includes('Performance is'))
        //           if(filterAlertPerformance.length) {
        //             db.collection('Sites').doc(doc.data().idsite).get()
        //             .then(docSite => {
        //               var urlConvert = new URL(docSite.data().homepage)
        //               var urlModificada = urlConvert.host.replace(/\./g, "_");

        //               var newAlertToSave = []
        //               var existchanges = false
        //               filterAlertPerformance.forEach(itemAlert => {
        //                 if(itemAlert.lN) {
        //                   var doNewNotification = false
        //                   if(itemAlert.frequency === 'Daily') {
        //                       var fechaLastUpdated = moment(parseInt(itemAlert.lN, 10))
        //                       var fechaActualFin = moment(Date.now())
        //                       var diffUpdated = fechaActualFin.diff(fechaLastUpdated, 'minutes')
        //                       if(diffUpdated >= 0) {
        //                           doNewNotification = true
        //                       }
        //                   } else if(itemAlert.frequency === 'On a week') {
        //                       var fechaLastUpdated = moment(parseInt(itemAlert.lN, 10))
        //                       var fechaActualFin = moment(Date.now())
        //                       var diffUpdated = fechaActualFin.diff(fechaLastUpdated, 'minutes')
        //                       if(diffUpdated >= 9580) {
        //                           doNewNotification = true
        //                       }
        //                   } else if(itemAlert.frequency === 'Monthly') {
        //                       var fechaLastUpdated = moment(parseInt(itemAlert.lN, 10))
        //                       var fechaActualFin = moment(Date.now())
        //                       var diffUpdated = fechaActualFin.diff(fechaLastUpdated, 'minutes')
        //                       if(diffUpdated >= 43610) {
        //                           doNewNotification = true
        //                       }
        //                   }
                          
        //                   if(doNewNotification) {
        //                       existchanges = true
        //                       itemAlert.lN = Date.now()

        //                       var filterAlert = newAlertToSave.filter(ele => ele.url === urlModificada)
        //                       if(filterAlert.length) {
        //                           filterAlert[0].dataAlert.push(itemAlert)
        //                       } else {
        //                           newAlertToSave.push({
        //                               url: urlModificada,
        //                               dataAlert: [itemAlert]
        //                           })
        //                       }
        //                   } else {
        //                       filterAlertExludePerformance.push(itemAlert)
        //                   }
        //               } else {
        //                   existchanges = true
        //                   itemAlert.lN = Date.now()

        //                   var filterAlert = newAlertToSave.filter(ele => ele.url === urlModificada)
        //                   if(filterAlert.length) {
        //                       filterAlert[0].dataAlert.push(itemAlert)
        //                   } else {
        //                       newAlertToSave.push({
        //                           url: urlModificada,
        //                           dataAlert: [itemAlert]
        //                       })
        //                   }
        //               }
        //           })

        //           newAlertToSave.forEach(itemObj => {
        //               var urlModificada = itemObj.url
                      
        //               console.log('process: ',urlModificada);
        //               var config = {
        //                   'url': `https://psi-wwkeahz7bq-uc.a.run.app/v2/site/lastScores/${urlModificada}`,
        //                   'method': 'GET',
        //                   'timeout': 0
        //               };

        //               this.axios(config)
        //               .then((response) => {
        //                 if(response.data.status === 200) {
        //                   console.log(response);
        //                 }
        //               })
        //               .catch(err => {
        //                   console.error('Err get scores:', err.message)
        //                   // return res.status(404).send(err.message)
        //               })
        //             })
        //           })
        //         }
        //       })
        //     })
        //   }
        // })
      } else {
        // this.submitUpateAutomate()
        document.body.style.overflow = 'auto'
        this.infoIdsite = ''
      }
    },
    editConclusionId(val) {
      if(val) {
        this.loadingOpen = true
        setTimeout(() => {
          this.loadingOpen = false
        }, 1500)
      } else {
        this.loadingOpen = false
      }
    }
    // idsite(val) {
      
    // }
  },
  validations: {
  },
  methods: {
    onDragStart() {
      this.isDragging = true;
    },
    onDragEnd() {
      this.isDragging = false;
      if (!this.loadingOpen) {
        this.submitUpateAutomate();
      }
    },
    onChange() {
      if (!this.isDragging && !this.loadingOpen) { 
        // Solo llama a la función si no está en drag
        this.submitUpateAutomate();
      }
    },
    submitUpateAutomate() {
      if (this.performanceArray.length && !this.loadingOpen && this.showOpenCreateAlert) {
        db.collection('Performance').doc(this.idSitePerformance).update({
          data: this.performanceArray,
        })
        .then(() => {
          if (!this.loadingSaveAutomated) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Alert updated successfully`,
                icon: 'BellIcon',
                variant: 'success',
              },
            });
          }
          this.loadingSaveAutomated = true;

          setTimeout(() => {
            this.loadingSaveAutomated = false;
          }, 2300);
        });
      }
    },
    // submitUpateAutomate() {
    //   if(this.performanceArray.length && !this.loadingOpen && this.showOpenCreateAlert) {
    //     db.collection('Performance').doc(this.idSitePerformance).update({
    //       data: this.performanceArray
    //     })
    //     .then(() => {
    //       if(!this.loadingSaveAutomated) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: `Alert updated successfully`,
    //             icon: 'BellIcon',
    //             variant: 'success',
    //           },
    //         })
    //       }
    //       this.loadingSaveAutomated = true

    //       setTimeout(() => {
    //         this.loadingSaveAutomated = false
    //       }, 2300)
    //     })
    //   }
    // },
    submitUpate() {
      this.loadingUpdateAlert = true
      db.collection('Performance').doc(this.idSitePerformance).update({
        data: this.performanceArray
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Alert updated successfully`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.$refs.sidebar_create_alert.hide()
        setTimeout(() => {
          this.loadingUpdateAlert = false
        }, 1500)
      })

      // db.collection('Performance').add({
      //   idsite: this.idsite.id,
      //   projectid: this.idsite.projectid,
      //   page: this.pageUrl,
      //   condition: this.whenCondition,
      //   conclusion: conclusion,
      //   frequency: this.frequency,
      //   type: 'Performance'
      // })
    },
    submitEditar(payload) {
        var conclusion = this.whenCondition === 'Performance is higher than' || this.whenCondition === 'Performance is lower than' ? this.conclusionCondition + '%' : this.conclusionCondition + 's'
        var filterAlreadyAdd = this.performanceArray.filter(ele => ele.page === this.pageUrl && ele.condition === this.whenCondition && ele.conclusion === conclusion && ele.frequency === this.frequency)

        if(filterAlreadyAdd.length) {
            this.creatingAlert = false
            this.loadingCreateAlert = false
            this.pageUrl = ''
            this.frequency = 'On a week'
            this.whenCondition = ''
            this.conclusionCondition = null
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: `Error: alert already created`,
                    icon: 'BellIcon',
                    variant: 'warning',
                },
            })
        } else {
            db.collection('Performance').add({
                idsite: this.idsite.id,
                projectid: this.idsite.projectid,
                page: this.pageUrl,
                condition: this.whenCondition,
                conclusion: conclusion,
                frequency: this.frequency,
                type: 'Performance'
            })
            .then(() => {
                this.performanceArray.push({
                    type: 'Performance',
                    condition: this.whenCondition,
                    conclusion: conclusion,
                    frequency: this.frequency,
                    page: this.pageUrl
                })
                this.creatingAlert = false
                this.loadingCreateAlert = false
                this.pageUrl = ''
                this.frequency = 'On a week'
                this.whenCondition = ''
                this.conclusionCondition = null
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Alert created successfully`,
                      icon: 'BellIcon',
                      variant: 'success',
                    },
                })
                this.$refs.sidebar_create_alert.hide()
            })
        }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            // eslint-disable-next-line
            //   alert('form submitted!')
            this.loadingCreateAlert = true
            this.submitEditar()
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.create-alert-frequency-select-form {
  font-family: 'Hurme Geometric Sans 3';
  margin-top: 5px;
  /* border: 0px;   */
}
.create-alert-frequency-select-form option {
  color: rgb(75 85 99);
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: 400;
  /* font-family: 'Hurme Geometric Sans 3'; */
  font-family: Tahoma,Geneva,sans-serif
}
.create-alert-frequency-select {
  font-family: 'Hurme Geometric Sans 3';
  margin-top: 5px;
  border: 0px;
}
.create-alert-frequency-select option {
  color: rgb(75 85 99);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  /* font-family: 'Hurme Geometric Sans 3'; */
  font-family: Tahoma,Geneva,sans-serif
}
.frequency-create-alert ul.dropdown-menu.show {
  border-radius: 10px !important;
  /* padding-top: 0px !important;
  padding-bottom: 0px !important; */
  padding: 0.5rem 0 !important;
}
.frequency-create-alert ul.dropdown-menu {
  color: #656b85 !important;
}
.frequency-create-alert ul.dropdown-menu:hover {
  color: #83889d !important;
}
.frequency-create-alert .btn-outline-primary {
  min-width: 140px;
  text-align: left;
  /* padding-left: 10px !important; */
}
.frequency-create-alert .dropdown-toggle::after {
  float: right !important;
  position: relative;
  top: 6px;
}
.frequency-create-alert .dropdown-item {
  padding: 0.65rem 1.28rem !important;
  clear: both;
  text-align: inherit;
  color: #656b85 !important;
}
.frequency-create-alert .dropdown-item:hover {
  background: none !important;
  color: #83889d !important;
}
.slider-custom-plan .vue-slider-process {
  background-color: #7838ff;
}
.slider-custom-plan .vue-slider-dot-tooltip-inner {
  background-color: #7838ff;
  border-color: #7838ff;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-create-alert {
    width: 90%;
    max-width: 793px !important;
}
</style>