<template>
  <div>
    <sidebar-take-screenshot :pro-pages.sync="proPages" :idsite="idsite" :isTakeScreenshotSidebarActive.sync="showOpenTakeScreenshot" />

    <div class="head d-flex justify-content-between align-items-center position-relative">
      <h5 class="text-gray-500 m-0 pl-1">
        {{ stringDate ? 'Screenshots for ' +stringDate : '' }}
      </h5>
      <div class="d-flex justify-content-end">
        <b-button
          class="btn-icon mr-1 takeScreenshotNowBtn"
          @click.prevent="getTakeScreenshot()"
        >
          <feather-icon
            icon="CameraIcon"
            class="inline-block"
          /> Take Screenshot Now
        </b-button>
      </div>
    </div>
    <div
      id="historyTable"
      class="table-auto overflow-x-hidden overflow-y-auto block mt-1"
      style="height:22rem"
    >
      <div
        v-if="!history"
        class="h-full w-full position-relative"
      >
        <div class="text-center m-auto top-0 bottom-0 left-0 right-0 absolute h-16">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            variant="primary"
          />
          <strong class="text-primary block">Loading</strong>
        </div>
      </div>
      <div v-show="history && !emptyData" class="header-history d-flex mb-1">
        <div class="col-4 pl-1 font-weight-normal">Page</div>
        <div class="col-5 font-weight-normal">Comments</div>
        <div class="col-2 font-weight-normal"></div>
        <div class="col-1 font-weight-normal px-0">Time</div>
      </div>

      <div v-if="history && !emptyData" class="body-history">
        <template v-for="(screenshot, key) in history">
          <article
            :id="key"
            :key="key"
            class="h-24 d-flex align-items-center historyItem cursor-pointer"
            :class="{ 'active': (toShow.data && (toShow.data.id === key)) }"
            :style="getStyles(screenshot.page)"
            @click="previewScreenshots(screenshot, key)"
          >
            <div class="px-1 col-4 truncate" style="padding-bottom: 7px; padding-top: 7px; font-size: 15px;">
              {{ getTitlePage(allData.pages[screenshot.page]) }} <span class="d-block truncate" style="color: rgb(156, 163, 175); font-size: 13px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 280px;" :title="allData.pages[screenshot.page]">
                <b-button
                  style="background: transparent !important; border: 0px; position: relative; top: -3px; margin-right: 1px;"
                  target="_blank"
                  :href="allData.pages[screenshot.page]"
                  class="p-0 ml-0 linkURL eyeBtns pl-0"
                  @click.stop
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    class="cursor-pointer eyeBtns"
                    style="color: rgb(176, 176, 176) !important;"
                    size="16"
                  />
                </b-button>
                {{ getPathnamePage(allData.pages[screenshot.page]) }}
              </span>
            </div>
            <p class="col-5 m-0">
              {{ screenshot.comment }}
            </p>
            <div class="d-flex col-2">
              <div class="text-center">
                <b-button variant="light" :class="{ 'active': (toShow.data && (toShow.data.id === key)) }" class="previewScreenshotBtn desktopMode" style="padding: 8.4px !important; margin: 0 2.8px;" @click="previewScreenshots(screenshot, key)">
                  <feather-icon icon="EyeIcon" class="d-inline" />
                </b-button>
                <b-button class="shareBtn" :variant="(activeItem == key && copied) ? 'success' : 'light'" style="padding: 8.4px !important; margin: 0 2.8px;" @click="shareLink({ key: key, page: screenshot.page })">
                  <feather-icon :icon="(copied && activeItem == key) ? 'CopyIcon' : 'Share2Icon'" class="d-inline" />
                </b-button>
              </div>
            </div>
            <div class="d-flex col-1 px-0">
              <span class="text-center pr-25">
                {{ getTheTime(key) }}
              </span>
            </div>
          </article>
        </template>
      </div>

      <div
        v-else
        class="d-flex pt-4 justify-content-center h-100"
      >
        <h3 class="text-gray">
          No screenshots found for {{ stringDate }}
        </h3>
      </div>
    </div>
    <b-button
      class="mt-2 see-more"
      @click="$emit('moreRecentMode')"
    >
      See more recent
    </b-button>
    <SidebarPreviewsMobile
      :is-previews-sidebar-active.sync="isPreviewSidebarActive"
      :preview-to-show="toShow"
    />
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SidebarPreviewsMobile from './SidebarPreviewsMobile.vue'
import SidebarTakeScreenshot from './SidebarTakeScreenshot.vue'
import functions from './screenshotsFunctions'

export default {
  name: 'HistoryScreenshots',
  components: {
    BButton,
    BSpinner,
    SidebarPreviewsMobile,
    SidebarTakeScreenshot,
  },
  directives: {
    Ripple,
  },
  props: {
    currentDate: {
      type: String,
      default: '',
      required: true,
    },
    allData: {
      type: Object,
      required: true,
      default: () => {},
    },
    proPages: {
      type: Array,
      required: true,
      default: () => [],
    },
    idsite: {
      type: Object,
      default: false,
    }
  },
  data() {
    return {
      history: null,
      stringDate: null,
      inDownload: '',
      // To active items
      activeItem: null,
      isPreviewSidebarActive: false,
      moreRecentPreviews: {},
      emptyData: false,
      toShow: {},
      copied: false,
      showOpenTakeScreenshot: false,
      logicEven: 0,
      dataSrc: null,
      backgroundColors: {},
      timerInfiniteScreenshot: null
    }
  },
  watch: {
    currentDate() {
      this.recoverHistory()
    },
    allData() {
      this.recoverHistory(this.allData.moreRecent)
    },
  },
  created() {
    this.timerInfiniteScreenshot = null
    this.recoverHistory(this.allData.moreRecent)
  },
  // mounted() {
  //   this.timerInfiniteScreenshot = setInterval(() => {
  //     this.getStatusOpenScreenshot()
  //   }, 500)
  // },
  // destroyed() {
  //   clearInterval(this.timerInfiniteScreenshot)
  // },
  methods: {
    // getStatusOpenScreenshot() {
    //   // console.log('ser')
    //   var takeScreenShare = localStorage.getItem('openTakeScreenShare')
    //   // console.log(takeScreenShare)
    //   if(takeScreenShare) {
    //     // console.log('first')
    //     setTimeout(() => {
    //       localStorage.removeItem('openTakeScreenShare')
    //     }, 500)
    //     this.showOpenTakeScreenshot = true
    //   }
    // },
    // :style="{ backgroundColor: getBackgroundColor(allData.pages[screenshot.page]) }"
    getBackgroundColor(page) {
      // Verificar si ya se asignó un color para esta URL de página
      if (this.backgroundColors[page] !== undefined) {
        return this.backgroundColors[page];
      } else {
        // Si no se ha asignado un color para esta URL de página, asignar uno de los dos colores posibles
        const color = Object.keys(this.backgroundColors).length % 2 === 0 ? '#f4f4f4' : '#fff';
        this.backgroundColors[page] = color;
        return color;
      }
    },
    getBorder(page) {
      if (this.backgroundColors[page] !== undefined) {
        return this.backgroundColors[page];
      } else {
        // Si no se ha asignado un color para esta URL de página, asignar uno de los dos colores posibles
        const color = Object.keys(this.backgroundColors).length % 2 === 0 ? '#f4f4f4' : '#fff';
        this.backgroundColors[page] = color;
        return color;
      }
    },
    getStyles(page) {
      return {
        backgroundColor: this.getBackgroundColor(this.allData.pages[page]),
        border: this.getBorder(this.allData.pages[page])
      };
    },
    // getBackgroundColor(page) {
    //   // Llamar a getCounterArticle sin modificar el estado
    //   const logicEven = this.calculateCounterArticle(page);

    //   // Devolver el color basado en el valor de logicEven
    //   return logicEven === 1 ? '#f4f4f4' : '';
    // },
    // calculateCounterArticle(page) {
    //   // Lógica para calcular logicEven
    //   let logicEven = 0;
    //   if (this.dataSrc !== page) {
    //     logicEven = this.logicEven === 1 ? 2 : 1;
    //   }
    //   return logicEven;
    // },
    recoverHistory(day) {
      // console.log(this.currentDate,day);
      if (!this.currentDate && !day) return
      const req = !day ? this.currentDate : day
      const resultData = this.allData.screenshots[req]
      if (resultData) {
        this.stringDate = functions.generateDateString(this.currentDate, false)
        this.emptyData = false
        this.history = resultData
        const lastItem = {}
        const [firstKey] = Object.keys(resultData)
        lastItem.page = firstKey
        lastItem.data = resultData[lastItem.page]
        day ? this.previewScreenshots(lastItem.data, lastItem.page) : null
        this.moreRecentPreviews = lastItem
        this.activeItem = lastItem.page
      } else {
        this.history = {}
        this.emptyData = true
        this.$emit('showPreviews', null)
      }
    },
    getTakeScreenshot() {
      if(this.$route.name === 'shared-site') {
        localStorage.setItem('takeScreenShare', 'true')
      } else {
        this.showOpenTakeScreenshot = true
      }
    },
    getTitlePage(pageURL) {
      const vector = this.proPages
      if (pageURL === this.allData.pages[this.allData.home]) {
        return 'Home'
      }
      if (vector.length) {
        for (let i = 0; i < vector.length; i += 1) {
          const arrayActual = vector[i]
          if (arrayActual?.url === pageURL) {
            return arrayActual.title ? arrayActual.title : ''
          }
        }
      }
      return ''
    },
    getPathnamePage(pageURL) {
      var urlConvert = new URL(pageURL)
      var urlConvertToPath = urlConvert.pathname === '/' ? 'Homepage' : urlConvert.pathname
      return urlConvertToPath
    },
    getTheTime(val) {
      const timestamp = Number(val)
      const date = new Date(timestamp)
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },
    previewScreenshots(data, key) {
      if (!data && !key) return
      const windowsWidth = window.innerWidth
      // console.log(windowsWidth);
      if (windowsWidth < 740) {
        this.isPreviewSidebarActive = true
      } else {
        var valTab = this.$route.query.section
        // console.log(valTab, window.location.search);
        if(valTab === 'tools' || (window.location.search && window.location.search.includes('section=tools&content=screen'))) {
          setTimeout(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            })
          }, 500)
        }
      }
      this.activeItem = key
      this.toShow = {}
      this.toShow.page = data.page
      this.toShow.data = { id: key, comment: data.comment }
      this.$emit('showPreviews', this.toShow)
    },
    shareLink(data) {
      // const url = this.$router.resolve(({ path: `/shared-screenshots/${this.allData.home}`, query: data }))
      // console.log(this.allData.home, data)
      // Obtener la URL del enlace
      var url = `https://screenshots.seocloud.ai/${this.allData.home}?key=${data.key}&page=${data.page}`
      this.$copyText(url)
      window.open(url, '_blank')

      // const link = document.location.origin + url.href
      // navigator.clipboard.writeText(link)
      this.activeItem = data.key
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 20000)
      // window.open(link, '_blank')
    },
  },
}
</script>

<style>
#historyTable article:nth-child(odd) {
  border-bottom: #fafafc solid 1px;
}

#historyTable article:nth-child(even) {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-bottom: white solid 1px;
}
/* #historyTable article .even-article-group {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-bottom: white solid 1px;
} */
.historyItem.active{
  background: #F0F1F3 0% 0% no-repeat padding-box !important;
}
.see-more{
  background: #CA34FF30 0% 0% no-repeat padding-box !important;
  border: 1px solid #CA34FF!important;
  border-radius: 23px;
  color: #CA34FF!important;
  font: normal normal 16px/20px "Hurme Geometric Sans 3" !important;
  float: right;
}
.see-more:active,
.see-more:focus{
  background: #CA34FF !important;
  color: white !important;
}
.buttons button{
  padding: 8.4px !important;
  margin: 0 2.8px;
  border: none !important;
}
.buttons button:hover{
  background: #7838FF !important;
}
.buttons button:hover svg{
  color:#fff;
}
.historyItem:hover .previewScreenshotBtn,
.historyItem.active .previewScreenshotBtn{
  background: #7838FF !important;
  -webkit-box-shadow: 0 8px 25px -8px #82868b;
  box-shadow: 0 8px 25px -8px #82868b;
}
.historyItem:hover .previewScreenshotBtn svg,
.historyItem.active .previewScreenshotBtn svg,
.shareBtn:hover svg{
  color: white !important;
}
.historyItem:hover .previewScreenshotBtn,
.previewScreenshotBtn .feather-copy{
  color: #000;
}
.buttons button.btn-light{
  background: #F9F9F9;
}
.buttons button.btn-light svg{
  color:#A7AAB9;
}
.text-gray-500 {
  opacity: 1;
  color: rgba(107, 114, 128) !important;
  font-weight: inherit;
  font-size: inherit;
}
.takeScreenshotNowBtn {
  border-radius: .358rem !important
}
.body-history {
  height: 20rem;
  max-height: 20rem;
  overflow: auto;
}
</style>