<!-- MyTextArea.vue -->
<template>
    <b-form-textarea
        class="mt-75"
        :placeholder="placeholder"
        rows="3"
        max-rows="17"
        v-model="internalValue"
    />
</template>

<script>
import { BTabs, BTab, BCardText, BOverlay, BListGroup, BListGroupItem, BButton, BImgLazy, BFormTextarea, } from 'bootstrap-vue'

export default {
    // props: ['placeholder', 'model'],
    components: {
        BFormTextarea,
    },
    props: {
    value: { // Vue 2 utiliza `value` por defecto con `v-model`
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: this.value // Sincroniza el valor inicial con la prop `value`
    };
  },
  watch: {
    internalValue(newValue) {
      this.debouncedEmit(newValue); // Emite el valor con debounce al cambiar `internalValue`
    },
    value(newValue) {
      this.internalValue = newValue; // Sincroniza si el valor de `value` cambia desde el padre
    }
  },
  created() {
    // Inicializa la función debounce con un retraso de 300 ms
    this.debouncedEmit = this.debounce(value => {
      this.$emit('input', value); // Emite el evento `input` al padre para actualizar el valor
    }, 300);
  },
  methods: {
    // Función de debounce que retrasa la ejecución de `func` hasta después de `wait` ms
    debounce(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }
  }
    // computed: {
    //     internalModel: {
    //         get() {
    //             return this.model;
    //         },
    //         set(value) {
    //             this.$emit('update:model', value);
    //         }
    //     }
    // },
    // data() {
    //     return {
    //         localModel: this.model
    //     };
    // },
    // watch: {
    //     // model(newVal) {
    //     //     this.localModel = newVal;
    //     // },
    //     // localModel() {
    //     //     // this.updateModel()
    //     // }
    // },
    // methods: {
    //     // updateModel() {
    //     //     this.$emit('update:model', this.localModel);
    //     //     // this.$refs.myTextarea.focus();
    //     // }
    // }
}
</script>