<template>
  <div
    id="preview-title"
    class="share-title"
  >
    <template v-if="url">
      <h2 :class="white ? 'text-white' : ''" style="font-size: 17.6px !important;">
        URL:
      </h2>
      <a
        class="truncate"
        :class="white ? 'text-white' : ''"
        :href="url"
        target="_blank"
        style="font-size: 17.6px !important;"
      ><feather-icon
        size="16"
        icon="ExternalLinkIcon"
        class="cursor-pointer"
      />{{ url }}</a>
    </template>
    <template v-if="date">
      <b>Date:</b>
      <time
        :datetime="date"
      >{{ date }}</time>
    </template>
    <template v-if="comment">
      <b>Comment:</b>
      <p>
        {{ comment }}
      </p>
    </template>
    <template v-if="typeDevice">
      <b>Size: </b>
      <p class="capitalize">
        {{ typeDevice }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    comment: {
      type: String,
      required: true,
      default: '',
    },
    typeDevice: {
      type: String,
      required: false,
      default: '',
    },
    date: {
      type: String,
      required: true,
      default: '',
    },
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  .share-title {
    h2{
      font-size: 22px;
      margin-bottom: 0;
      font-weight: 700;
    }
    a{
    display: flex;
    align-items: center;
    color: #CA34FF;
    font-size: 22px;
    margin-bottom: 8px;
    svg {
      margin-right: 6px;
    }
    }
    time{
      font-size: 16px;
      display: block;
    }
    p{
      font-size: 16px;
    }
  }
</style>