<!-- MyTextArea.vue -->
<!-- :state="isValidUrl" -->
<template>
    <div>
        <b-form-input
            class="mt-75"
            :placeholder="placeholder"
            v-model="internalValue"
            @input="validateAndEmit"
            :disabled="disabledInput"
            required
        />
        <span v-if="!isValidUrl && internalValue" style="text-align: left; width: 100%; display: block; color: #fc9c9c;">URL is invalid</span>
    </div>
</template>

<script>
import { BTabs, BTab, BCardText, BOverlay, BListGroup, BListGroupItem, BButton, BImgLazy, BFormInput, } from 'bootstrap-vue'

export default {
    // props: ['placeholder', 'model'],
    components: {
        BFormInput,
    },
    props: {
        value: { // Vue 2 utiliza `value` por defecto con `v-model`
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isValidUrl: {
            type: Boolean,
            default: false
        },
        disabledInput: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internalValue: this.value,
            //   isValidUrl: null
        };
    },
    watch: {
        internalValue(newValue) {
            this.debouncedEmit(newValue); // Emite el valor con debounce al cambiar `internalValue`
        },
        value(newValue) {
            this.internalValue = newValue; // Sincroniza si el valor de `value` cambia desde el padre
        }
    },
    created() {
        this.debouncedEmit = this.debounce(value => {
            this.$emit('input', value); // Emite el evento `input` al padre para actualizar el valor
        }, 300);
    },
    methods: {
        debounce(func, wait) {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        },
        validateAndEmit() {
            // Expresión regular para validar la URL
            const urlPattern = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)([\w\-._~:/?#[\]@!$&'()*+,;=.]+)?$/;
            
            // Verificar si el valor ingresado es una URL válida
            var isValidUrlAtom = urlPattern.test(this.internalValue);
            // console.log(isValidUrlAtom);
            if(!this.internalValue.includes('http://') && !this.internalValue.includes('https://')) {
                isValidUrlAtom = false
            }
            
            // Emitir el valor de la URL y el estado de la validación al componente padre
            // this.$emit('input', this.inputValue); // Emitir el valor de la URL
            this.$emit('update:isValidUrl', isValidUrlAtom); // Emitir el estado de la validación
        }
    }
}
</script>