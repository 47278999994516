<template>
  <div>
    <b-sidebar
      id="sidebar-right-delete-strings-search"
      ref="sidebar_delete_strings_search"
      :visible="showOpenDeleteStringsSearch"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-delete-strings-search', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body"
          style="padding: 40px;"
        >
          <h3
            class="mb-0 pb-0"
            style="font-weight: 700; color: #181E38 !important;"
          >
            Are you sure you want to delete the {{ type === 'suspicious' ? 'suspicious text' : 'string'}}?
          </h3>
          <!-- <claim-ownership style="padding: 40px;" class="mt-0 pt-0 pb-0" :name="idsite.name" :url="idsite.domain" :keySite="idsite.key" /> -->
          <b-button
            style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-3 mr-1"
            variant="primary"
            @click="deleteFunction"
            :disabled="submitProcess"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #FFFFFF;"
            >Yes</span>
          </b-button>
          <b-button
            style="background: #F0F1F7 !important; border: 1px solid #CFD3E5 !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-3"
            variant="primary"
            @click="hide"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #A7AAB9;"
            >Cancel</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenDeleteStringsSearch',
    event: 'update:show-open-delete-strings-search',
  },
  props: ['idsite', 'showOpenDeleteStringsSearch', 'stringToDelete', 'strings', 'type'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      submitProcess: false,
      stringsArray: [],
    }
  },
  watch: {
    watch: {
      showOpenDeleteStringsSearch(val) {
        if(val) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      }
    },
    // strings(val) {
    //   if(!this.showOpenDeleteStringsSearch) {
    //     this.stringsArray = val
    //   }
    // }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_delete_strings_search.hide()
    },
    deleteFunction() {
        if(this.idsite.domain) {
            var hostUrl = this.idsite.domain
            db.collection('Sites').where('WebsiteURL', '==', hostUrl).limit(1).get()
            .then(itemSite => {
                if(itemSite.size) {
                    itemSite.forEach(docS => {
                        var str = this.type === 'suspicious' ? (docS.data().suspiciousText ? docS.data().suspiciousText : []) : docS.data().stringSearch ? docS.data().stringSearch : []
                        this.stringsArray = str
                        var filterTaxonomy = this.stringsArray.filter(tag => {
                          return tag !== this.stringToDelete;
                        })
                        // console.log(filterTaxonomy)
                        if(this.type === 'suspicious') {
                          db.collection('Sites').doc(docS.id).update({
                              suspiciousText: filterTaxonomy,
                              pageSizes: []
                          })
                          this.$emit('update:strings', filterTaxonomy)
                          this.$emit('update:stringToDelete', '')

                          this.$toast({
                              component: ToastificationContent,
                              props: {
                                  title: 'Suspicious text has been deleted',
                                  icon: 'BellIcon',
                                  variant: 'success',
                              }
                          })
                          this.$refs.sidebar_delete_strings_search.hide()
                        } else {
                          db.collection('Sites').doc(docS.id).update({
                              stringSearch: filterTaxonomy,
                              pageSizes: []
                          })
                          this.$emit('update:strings', filterTaxonomy)
                          this.$emit('update:stringToDelete', '')

                          this.$toast({
                              component: ToastificationContent,
                              props: {
                                  title: 'String has been deleted',
                                  icon: 'BellIcon',
                                  variant: 'success',
                              }
                          })
                          this.$refs.sidebar_delete_strings_search.hide()
                        }
                    })
                }
            })
        }
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-delete-strings-search {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
