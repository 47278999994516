<template>
  <div>
    <b-sidebar
      id="sidebar-right-end-crawl"
      ref="sidebar_end_crawl"
      :visible="showOpenSidebarEndCrawl"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-sidebar-end-crawl', val)"
    >
        <template #default="{ hide }">
            <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
                style="background-color: #F0F1F7; height: 91px;"
            >
                <b-card
                    no-body
                    class="overflow-hidden w-100"
                    style="background-color: #F0F1F7;"
                >
                    <header-sidebar :idsite-header.sync="idsite" />
                </b-card>
                <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    size="20"
                    @click="hide"
                />
                </div>
                <div class="ml-3 my-2 mt-3">
                <h4 class="mb-1">Crawl finished {{ idsite.name }}:</h4>
                <h4 class="mb-1" style="font-size: 15px; text-transform: capitalize;">Status: {{ crawl.estatus === 'running' ? 'Partial' : crawl.estatus === 'incomplete' ? 'Partial' : crawl.estatus === 'endSuccess' ? 'Completed' : crawl.estatus === 'terminated' ? 'Terminated' : crawl.estatus === 'timeout' ? 'Timeout' : crawl.estatus ? crawl.estatus : 'Partial' }}</h4>
                <div class="mt-2">
                    <h3
                        class="mb-2 mt-75"
                        style="font-weight: 700;"
                    >
                        Pages: {{ totalpages }}
                    </h3>
                </div>
                <div style="display: grid !important">
                    <h2
                        class="float-left mb-0"
                        style="font-weight: 700; color: #7838ff !important; text-align: left; font-size: 28px !important;"
                    >
                    {{ errors }} <span style="font-weight: initial; color: #656B85; font-size: 1rem !important;">errors</span>
                    </h2>
                    <span
                    class=" float-left"
                    style="color: #656B85;"
                    >In all pages</span>
                </div>
                <app-collapse class="mb-1 mt-2">
                    <app-collapse-item title="Crawl again">
                        <b-row>
                            <b-col
                            md="12"
                            >
                            <h5
                                class="float-left pl-1 pt-50"
                                style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
                            >
                                Number to pages:
                            </h5>

                            <b-card
                                no-body
                                class="overflow-hidden w-100"
                            >
                                <b-row
                                no-gutters
                                class="mt-1 ml-3"
                                style="height: 110px;"
                                >
                                <b-col
                                    md="3"
                                    :class="`cards w-100 type-active mr-50 ${numberPages === '0' || numberPages === 0 ? 'type-active' : 'type-inactive'}`"
                                    style="height: 85px;"
                                >
                                    <label
                                    class="w-100"
                                    style="height: 85px;"
                                    role="button"
                                    >
                                    <input
                                        v-model="numberPages"
                                        type="radio"
                                        value="0"
                                        checked
                                        style="float: left; margin-top: 15px; display: none;"
                                    >
                                    <div
                                        class="pb-50"
                                        style="margin-top: 3px;"
                                    >
                                        <p
                                        class="color-text-dark mb-0"
                                        :style="numberPages === '0' || numberPages === 0 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                                        >
                                        25
                                        </p>
                                        <b-list-group
                                        class="list-group-circle mb-50 mt-50"
                                        style="width: 100% !important; display: block; text-align: center;"
                                        >
                                        <span
                                            :style="`padding-bottom: 0px; ${numberPages === '0' || numberPages === 0 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                        >
                                            Pages
                                        </span>
                                        </b-list-group>
                                    </div>
                                    </label>
                                    <span style="width: 100%; display: block; text-align: center;">{{ isloggedIn ? 'Free' : 'As Guess' }}</span>
                                </b-col>
                                <b-col
                                    md="3"
                                    :class="`cards w-100 ml-50 ${numberPages === '1' || numberPages === 1 ? 'type-active' : 'type-inactive'}`"
                                    style="height: 85px;"
                                >
                                    <label
                                    class="w-100"
                                    style="height: 85px;"
                                    role="button"
                                    >
                                    <input
                                        v-model="numberPages"
                                        type="radio"
                                        value="1"
                                        checked
                                        style="float: left; margin-top: 15px; display: none;"
                                    >
                                    <div
                                        class="pb-50"
                                        style="margin-top: 3px;"
                                    >
                                        <p
                                        class="color-text-dark mb-0"
                                        :style="numberPages === '1' || numberPages === 1 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                                        >
                                        100
                                        </p>
                                        <b-list-group
                                        class="list-group-circle mb-50 mt-50"
                                        style="width: 100% !important; display: block; text-align: center;"
                                        >
                                        <span
                                            :style="`padding-bottom: 0px; ${numberPages === '1' || numberPages === 1 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                        >
                                            Pages
                                        </span>
                                        </b-list-group>
                                    </div>
                                    </label>
                                    <span style="width: 100%; display: block; text-align: center;">Free</span>
                                </b-col>
                                <b-col
                                    md="3"
                                    :class="`cards w-100 ml-50 ${numberPages === '2' || numberPages === 2 ? 'type-active' : 'type-inactive'}`"
                                    style="height: 85px;"
                                >
                                    <label
                                    class="w-100"
                                    style="height: 85px;"
                                    role="button"
                                    >
                                    <input
                                        v-model="numberPages"
                                        type="radio"
                                        value="2"
                                        checked
                                        style="float: left; margin-top: 15px; display: none;"
                                    >
                                    <div
                                        class="pb-50"
                                        style="margin-top: 3px;"
                                    >
                                        <p
                                        class="color-text-dark mb-0"
                                        :style="numberPages === '2' || numberPages === 2 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                                        >
                                        100+
                                        </p>
                                        <b-list-group
                                        class="list-group-circle mb-50 mt-50"
                                        style="width: 100% !important; display: block; text-align: center;"
                                        >
                                        <span
                                            :style="`padding-bottom: 0px; ${numberPages === '2' || numberPages === 2 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                        >
                                            Pages
                                        </span>
                                        </b-list-group>
                                    </div>
                                    </label>
                                    <span style="width: 100%; display: block; text-align: center;">License</span>
                                </b-col>
                                </b-row>
                            </b-card>
                            </b-col>
                            <b-col
                                md="12"
                            >
                                <h5
                                    class="float-left pl-1 pt-50"
                                    style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
                                >
                                    Crawl type:
                                </h5>

                                <b-card
                                    no-body
                                    class="overflow-hidden w-100"
                                >
                                    <b-row
                                        no-gutters
                                        class="mt-1 ml-3"
                                        style="height: 140px"
                                    >
                                        <b-col
                                            md="3"
                                            :class="`cards w-100 type-active mr-50 ${crawlType === '0' || crawlType === 0 ? 'type-active' : 'type-inactive'}`"
                                            style="height: 85px;"
                                        >
                                            <label
                                                class="w-100"
                                                style="height: 85px;"
                                                role="button"
                                            >
                                            <input
                                                v-model="crawlType"
                                                type="radio"
                                                value="0"
                                                checked
                                                style="float: left; margin-top: 15px; display: none;"
                                            >
                                            <div
                                                class="pb-50"
                                                style="margin-top: 3px;"
                                            >
                                                <p
                                                    class="color-text-dark mb-0"
                                                    style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                                                >
                                                <b-img-lazy
                                                    fluid
                                                    :src="sitesImg"
                                                    alt="no sites img"
                                                    style="width: 61px; height: 40px;"
                                                    loading="lazy"
                                                />
                                                </p>
                                                <b-list-group
                                                    class="list-group-circle mb-50 mt-50"
                                                    style="width: 100% !important; display: block; text-align: center;"
                                                >
                                                <span
                                                    :style="`padding-bottom: 0px; ${crawlType === '0' || crawlType === 0 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                                >
                                                    Only HTML
                                                </span>
                                                </b-list-group>
                                            </div>
                                            </label>
                                            <span style="width: 100%; display: block; text-align: center;">{{ isloggedIn ? 'Free' : 'As Guess' }}</span>
                                        </b-col>
                                        <b-col
                                            md="3"
                                            :class="`cards w-100 ml-50 ${crawlType === '1' || crawlType === 1 ? 'type-active' : 'type-inactive'}`"
                                            style="height: 85px;"
                                        >
                                            <label
                                                class="w-100"
                                                style="height: 85px;"
                                                role="button"
                                            >
                                            <input
                                                v-model="crawlType"
                                                type="radio"
                                                value="1"
                                                checked
                                                style="float: left; margin-top: 15px; display: none;"
                                            >
                                            <div
                                                class="pb-50"
                                                style="margin-top: 3px;"
                                            >
                                                <p
                                                    class="color-text-dark mb-0"
                                                    style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                                                >
                                                <b-img-lazy
                                                    fluid
                                                    :src="siteJS"
                                                    alt="no sites img"
                                                    style="width: 30px; height: 40px;"
                                                    loading="lazy"
                                                />
                                                </p>
                                                <b-list-group
                                                    class="list-group-circle mb-50 mt-50"
                                                    style="width: 100% !important; display: block; text-align: center;"
                                                >
                                                <span
                                                    :style="`padding-bottom: 0px; ${crawlType === '1' || crawlType === 1 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                                >
                                                    Javascript Crawl
                                                </span>
                                                </b-list-group>
                                            </div>
                                            </label>
                                            <span style="width: 100%; display: block; text-align: center;">Free</span>
                                            <span style="width: 100%; display: block; text-align: center; color: #30cdff;">AI SEO Copilot</span>
                                        </b-col>
                                        <b-col
                                            md="3"
                                            :class="`cards w-100 ml-50 ${crawlType === '2' || crawlType === 2 ? 'type-active' : 'type-inactive'}`"
                                            style="height: 85px;"
                                        >
                                            <label
                                                class="w-100"
                                                style="height: 85px;"
                                                role="button"
                                            >
                                            <input
                                                v-model="crawlType"
                                                type="radio"
                                                value="2"
                                                checked
                                                style="float: left; margin-top: 15px; display: none;"
                                            >
                                                <div
                                                    class="pb-50"
                                                    style="margin-top: 3px;"
                                                >
                                                    <p
                                                        class="color-text-dark mb-0"
                                                        style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                                                    >
                                                        <b-img-lazy
                                                            fluid
                                                            :src="siteJS"
                                                            alt="no sites img"
                                                            style="width: 30px; height: 40px;"
                                                            loading="lazy"
                                                        />
                                                    </p>
                                                    <b-list-group
                                                        class="list-group-circle mb-50 mt-50"
                                                        style="width: 100% !important; display: block; text-align: center;"
                                                    >
                                                        <span
                                                            :style="`padding-bottom: 0px; ${crawlType === '2' || crawlType === 2 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                                                        >
                                                            Javascript Crawl
                                                        </span>
                                                    </b-list-group>
                                                </div>
                                            </label>
                                            <span style="width: 100%; display: block; text-align: center;">License</span>
                                            <span style="width: 100%; display: block; text-align: center; color: #ff9f43;">All pages (slow)</span>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-button
                            :style="$route.name === 'shared-site' && (numberPages === 1 || numberPages === 2 || numberPages === '1' || numberPages === '2') ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;'"
                            class="float-right mt-3"
                            variant="primary"
                            @click.prevent="submitEditar"
                            :disabled="processSubmit"
                        >
                            <span
                            class=""
                            style="font-weight: 700; color: #FFFFFF;"
                            >{{ $route.name === 'shared-site' && (numberPages === 1 || numberPages === 2 || numberPages === '1' || numberPages === '2') && !isloggedIn ? 'Sign up and Run Crawl' : 'Run Crawl' }}</span>
                        </b-button>
                    </app-collapse-item>
                </app-collapse>
            </div>
        </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BImgLazy, BListGroup,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BImgLazy,
    BListGroup,
    ClaimOwnership,
    AppCollapse,
    AppCollapseItem,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSidebarEndCrawl',
    event: 'update:show-open-sidebar-end-crawl',
  },
  props: ['idsite', 'showOpenSidebarEndCrawl', 'crawl', 'errors', 'totalpages'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      isloggedIn: false,
    }
  },
  watch: {
    showOpenSidebarEndCrawl(val) {
        this.isloggedIn = false
        const user = auth.currentUser
        if(val) {
            document.body.style.overflow = 'hidden'
            if(user.uid) {
                this.isloggedIn = true
                this.numberPages = 1
                
                if(this.$route.name === 'shared-site') {
                    this.crawlType = 1
                } else {
                    this.crawlType = 1
                }
            } else {
                if(this.$route.name === 'shared-site') {
                    this.numberPages = 0
                    this.crawlType = 0
                }
            }
        } else {
            document.body.style.overflow = 'auto'
        }
    }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      if(this.$route.name === 'shared-site' && (this.numberPages === 1 || this.numberPages === 2 || this.numberPages === '1' || this.numberPages === '2')) {
        this.$refs.sidebar_end_crawl.hide()
      } else {
        this.$refs.sidebar_end_crawl.hide()
      }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-end-crawl {
    width: 90%;
    max-width: 593px !important;
}
</style>
